import markets from "../../config/markets";
import { callContractWithoutParams, getMarketResolves } from "../../utils/callHelpers";
import MarketABI from '../../config/ABI/MarketABI.json';
import BigNumber from "bignumber.js";
import { getMarketInfo } from "../../utils/APIHelpers";

const fetchMarketsApi = async () => { 
    const data = await Promise.all(
        markets.map(async (marketConfig) => {
            const { contractAddress, network } = marketConfig;
            const info = await getMarketInfo(contractAddress);

            return {
                ...marketConfig,
                info
            }
        })
    )
    return data;
}

export default fetchMarketsApi;