import Contracts from '../../config/contracts';
import markets from "../../config/markets";
import { callContract, getForecastBalances, getUserLiquidity, getShareBalances, getOrderbookUserOrders, getOrderFromID, getUserData } from '../../utils/callHelpers';
import ForecastABI from '../../config/ABI/Forecast.json';
import MarketABI from '../../config/ABI/MarketABI.json';
import BigNumber from 'bignumber.js';
import { unpackOrderWithID, unpackOrderWithExtraInformation } from '../../utils/orderHelpers';


export const fetchMarketUserBalances = async (account) => {
    const forecast = Contracts.forecasts;
    const ammMarkets = markets.filter((x) => x.version === 'amm');
    const data_amm = await Promise.all(
        ammMarkets.map(async (market) => {
            const { forecasts: { happens, not }, network, contractAddress } = market;

            const happens_balance = await getForecastBalances(happens, account, network);
            const not_balance = await getForecastBalances(not, account, network);
            const liquidity = await getUserLiquidity(contractAddress, account, network);
            // const liquidity = callContract(MarketABI, contractAddress, 'balance_of', [account], network);
            // TODO add liquidity
            return {
                ...market,
                user_happens: new BigNumber(happens_balance).toString(),
                user_not: new BigNumber(not_balance).toString(),
                user_liquidity: new BigNumber(liquidity).toString()
            }
        })
    )
    const orderbookMarkets = markets.filter((x) => x.version === 'orderbook');
    const data_ob = await Promise.all(
        orderbookMarkets.map(async (market) => {
            const { network, contractAddress, orderbookAddress } = market;

            const userdata = await getUserData(account, contractAddress, orderbookAddress, network);
            console.log(userdata)
            const unpacked_happens_buy = userdata.user_orders.happens_buy.filter((order) => new BigNumber(order).isGreaterThan(0)).map((x) => unpackOrderWithExtraInformation(x, 'happens', 'buy'));
            const unpacked_happens_sell = userdata.user_orders.happens_sell.filter((order) => new BigNumber(order).isGreaterThan(0)).map((x) => unpackOrderWithExtraInformation(x, 'happens', 'sell'));

            const unpacked_not_buy = userdata.user_orders.not_buy.filter((order) => new BigNumber(order).isGreaterThan(0)).map((x) => unpackOrderWithExtraInformation(x, 'not', 'buy'));
            const unpacked_not_sell = userdata.user_orders.not_sell.filter((order) => new BigNumber(order).isGreaterThan(0)).map((x) => unpackOrderWithExtraInformation(x, 'not', 'sell'));
            
            const userOrders = [...unpacked_happens_buy, ...unpacked_happens_sell, ...unpacked_not_buy, ...unpacked_not_sell].sort((a,b) => b.orderid - a.orderid);

            return {
                ...market,
                happens: new BigNumber(userdata.happens_balance).toString(),
                not: new BigNumber(userdata.not_balance).toString(),
                // userOrders: unpackedOrders && unpackedOrders.length > 0 ? unpackedOrders : []
                userOrders: userOrders,
                // rawOrders: [...userdata.user_orders.happens_buy, ...userdata.user_orders.happens_sell, ...userdata.user_orders.not_buy, ...userdata.user_orders.not_sell]
            }
        })
    )

    return [...data_amm, ...data_ob];
}