import { ChakraProvider } from '@chakra-ui/react'
import { StarknetReactProvider } from '@web3-starknet-react/core'
import { Provider } from 'react-redux';
import { Provider as StarknetProvider } from 'starknet'
import { RefreshContextProvider } from './contexts/RefreshContext';
import { NetworkContextProvider } from './contexts/NetworkContext';
import { sepolia, mainnet } from "@starknet-react/chains";
import {
  StarknetConfig,
  publicProvider,
  argent,
  braavos,
  useInjectedConnectors,
  voyager
} from "@starknet-react/core";
import store from './state';

function getLibrary(provider, connector) {
  return new StarknetProvider(provider)
}

export function StarknetWalletProvider({ children }) {
    const { connectors } = useInjectedConnectors({
      // Show these connectors if the user has no connector installed.
      recommended: [
        argent(),
        braavos(),
      ],
      // Hide recommended connectors if the user has any connector installed.
      includeRecommended: "onlyIfNoConnectors",
      // Randomize the order of the connectors.
      order: "random"
    });
  
    return (
      <StarknetConfig
        chains={[mainnet, sepolia]}
        provider={publicProvider()}
        connectors={connectors}
        explorer={voyager}
      >
        {children}
      </StarknetConfig>
    );
  }
const Providers = ({children}) => {
    return ( // modal provider ekle
        <Provider store={store}>
            {/* <StarknetReactProvider  getLibrary={getLibrary}> */}
            <StarknetWalletProvider>
                <ChakraProvider toastOptions={{ defaultOptions: { position: 'bottom-right' } }}>
                    <NetworkContextProvider>
                        <RefreshContextProvider>
                            {children}
                        </RefreshContextProvider>
                    </NetworkContextProvider>
                </ChakraProvider>
            </StarknetWalletProvider>
           {/*  </StarknetReactProvider> */}
        </Provider>
    )
}

export default Providers;