import BigNumber from "bignumber.js"
// Felt252 decimal sayıyı unpack edicez.
export const unpackOrder = (order) => {
    // Orderid eklemiyoruz çünkü orderlar sonradan aggregate oluyor.

    // 1) Convert to hex string
    const hexOrder = new BigNumber(order).toString(16);

    // 2) En başına sıfır ekleyelim. Çünkü order status 0 gelir ise felt baştaki sıfırları siliyor.
    const paddedOrder = hexOrder.padStart(63, '0')

    // 3) Şimdi unpack yapalım 1 byte = 2 karakter = 8 bit.

    // orderid : 32 bit -> 6 karakter
    // date : 64 bit -> 16 karakter
    // amount: 128 bit -> 32 karakter
    // price: 16 bit -> 4 karakter
    // status: 8 bit -> 2 karakter

    // Sıralama ters olduğu için statusten başladım

    const status = paddedOrder.substring(0, 2); // 0, 1

    // 2. bit gereksiz

    const price = paddedOrder.substring(3, 7); // 2, 3, 4, 5

    const amount = paddedOrder.substring(7, 39); //

    const date = paddedOrder.substring(39, 55);

    // const orderid = paddedOrder.substring(55, 63);

    let unpackedOrder = {
        // orderid: new BigNumber(orderid).toString(),
        price: new BigNumber(parseInt(price, 16)).toString(),
        amount: new BigNumber(parseInt(amount, 16)).toString(),
        date: new BigNumber(parseInt(date, 16)).toString(),
        status : new BigNumber(status).toString()
    }

    return unpackedOrder
}

export const unpackOrderWithExtraInformation = (order, asset, side) => {
    const hexOrder = new BigNumber(order).toString(16);

    const paddedOrder = hexOrder.padStart(63, '0')
    // orderid : 32 bit -> 6 karakter
    // date : 64 bit -> 16 karakter
    // amount: 128 bit -> 32 karakter
    // price: 16 bit -> 4 karakter
    // status: 8 bit -> 2 karakter

    const status = paddedOrder.substring(0, 2); // 0, 1

    const price = paddedOrder.substring(3, 7); // 2, 3, 4, 5

    const amount = paddedOrder.substring(7, 39); //

    const date = paddedOrder.substring(39, 55);

    const orderid = paddedOrder.substring(55, 63);

    let unpackedOrder = {
        orderid: new BigNumber(parseInt(orderid, 16)).toString(),
        price: new BigNumber(parseInt(price, 16)).toString(),
        amount: new BigNumber(parseInt(amount, 16)).toString(),
        date: new BigNumber(parseInt(date, 16)).toString(),
        status : new BigNumber(status).toString(),
        asset,
        side
    }

    return unpackedOrder
}

/*
"0x127100000000064ef4f060000000000000000000000004247c5ff", 
"0x127100000000064ef4f0600000000000000000000000000989680", 
"0x1157c0000000064fa3beb00000000000000000000000012f711bb",
"0x1    157c          0000000064fa38ef0000000000000000000000000ad65345" 
        price

0101
0001
0000
0100
*/

export const unpackTrade = (trade) => {
    const hexTrade = new BigNumber(trade).toString(16);

    
    const paddedTrade = hexTrade.padStart(56, '0');

    const asset = paddedTrade.substring(0, 2); // u8
    const side = paddedTrade.substring(2, 4); // u8
    const price = paddedTrade.substring(4, 8); // u16
    const date = paddedTrade.substring(8, 24);
    const amount = paddedTrade.substring(28, 56);

    const unpackedTrade = {
        side: new BigNumber(side).toString(),
        asset: new BigNumber(asset).toString(),
        price: new BigNumber(parseInt(price, 16)).toString(),
        date: new BigNumber(parseInt(date, 16)).toString(),
        amount: new BigNumber(parseInt(amount, 16)).toString(),
    }

    return unpackedTrade
}

export const unpackOrderWithID = (order) => {
    const hexOrder = new BigNumber(order).toString(16);

    const paddedOrder = hexOrder.padStart(63, '0')
    // orderid : 32 bit -> 6 karakter
    // date : 64 bit -> 16 karakter
    // amount: 128 bit -> 32 karakter
    // price: 16 bit -> 4 karakter
    // status: 8 bit -> 2 karakter

    const status = paddedOrder.substring(0, 2); // 0, 1

    const price = paddedOrder.substring(3, 7); // 2, 3, 4, 5

    const amount = paddedOrder.substring(7, 39); //

    const date = paddedOrder.substring(39, 55);

    const orderid = paddedOrder.substring(55, 63);

    let unpackedOrder = {
        orderid: new BigNumber(parseInt(orderid, 16)).toString(),
        price: new BigNumber(parseInt(price, 16)).toString(),
        amount: new BigNumber(parseInt(amount, 16)).toString(),
        date: new BigNumber(parseInt(date, 16)).toString(),
        status : new BigNumber(status).toString()
    }

    return unpackedOrder
}

/* 
Dec : 10783978666860255917866806034807852348683020283280573855041071299428353
Hex : 190000000000000000000000000000F42400000000064E0DFA000000001
000 status
0190 price
000000000000000000000000000f4240 amount
0000000064e0dfa0 date
00000001 id
*/

export const aggregateOrders = (orders) => {
    if(orders && orders.length > 0) {
        const sum = orders.reduce((acc, cur)=> {
            const found = acc.find(val => val.price === cur.price)
            if(found){
                found.amount = new BigNumber(found.amount).plus(new BigNumber(cur.amount)).toString();
                found.aggregated = true;
            }else {
                acc.push({...cur, amount: Number(cur.amount)})
            }
            return acc
        }, [])
    
        return sum;
    } else {
        return orders;
    }

}

export const sumOrderAmounts = (orders) => {
    if(orders && orders.length > 0) {
        const sum = orders.reduce((parSum, val) => new BigNumber(parSum).plus(new BigNumber(val.amount)), [0])
    
        return sum.toString();
    } else {
        return 0;
    }
}