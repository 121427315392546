import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Stack,
    Text,
    useColorModeValue
  } from '@chakra-ui/react'
  import { useConnect } from '@starknet-react/core';

const ConnectWalletModal = function (props) {
    const { isOpen, onClose, title } = props;
    const { connect, connectors } = useConnect();

    const braavosBg = useColorModeValue('/images/icons/braavos_wallet_black.svg', '/images/icons/braavos_wallet_white.svg')
    const argentBg = useColorModeValue('/images/icons/argentX_wallet_black.svg', '/images/icons/argentX_wallet_white.svg')

    const bgColor = useColorModeValue('rgba(0,0,0,0.2)','rgba(255,255,255,0.1)');

    const connectorBgs = {
      "argentX" : useColorModeValue('/images/icons/argentX_wallet_black.svg', '/images/icons/argentX_wallet_white.svg'),
      "braavos" : useColorModeValue('/images/icons/braavos_wallet_black.svg', '/images/icons/braavos_wallet_white.svg')
    };


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Stack spacing={5}>
          {connectors.filter((connection) => connection.available()).map((connector) => (
            
            <Button _hover={{ backgroundColor : bgColor}} backgroundImage={connectorBgs[connector.id]} backgroundPosition={'center'} 
                  backgroundSize={'80%'} backgroundRepeat={'no-repeat'} height={68} 
                  onClick={() => {
                    connect({ connector });
                    onClose();
                    } } />
          ))}
          
          </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>Dismiss</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}

export default ConnectWalletModal;