import { Stack, Text, Heading, Skeleton, Box, Image, useColorModeValue } from "@chakra-ui/react";
import { getImageFromOrderbook, getMarketFromOrderbook, getRandomImageFromAddress, getMarketFromMarket } from "../../../utils/imageUtils";
import { useMarkets } from "../../../state/hooks";
import { getBalanceNumber } from "../../../utils/formatNumber";
import { formatAddress } from '../../../utils/formatAddress';
import { useNavigate } from "react-router-dom"
import BigNumber from "bignumber.js";
import { getChecksumAddress } from "starknet";

const RecentActivities = ({isLoaded, activities}) => {
    console.log(activities)
    return (
        <Stack width={'100%'}>
            <Heading size={'md'}>Recent Activity</Heading>
            <Skeleton isLoaded={isLoaded}>
                <Stack spacing={6} pt={2} pb={4}>
                    {activities.map((activity, index) => (
                        index <= 4 ? (<RenderActivityBar activity={activity} />) : (<></>)
                        
                    ))}
                </Stack>
            </Skeleton>
        </Stack>
    )
}

const RenderActivityBar = ({activity}) => {
    const greenColor = useColorModeValue('green.600', 'green.300');
    const redColor = useColorModeValue('red.600', 'red.300');
    const navigate = useNavigate();
    const markets = useMarkets();
    
    if(activity.hasOwnProperty('maker_order_id') && activity.hasOwnProperty('orderbook')) {
        // Trade
        const market = markets.find((x) => getChecksumAddress(x.orderbookAddress) === getChecksumAddress(activity.orderbook));
        // const market = getMarketFromOrderbook(activity.orderbook);
        const randomAvatar = getRandomImageFromAddress(activity.taker)

        const totalAmount = getBalanceNumber(new BigNumber(activity.price / 100).multipliedBy(new BigNumber(activity.matched_amount)).dividedBy(100).toString(), 6);
        return (
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Image src={market.image} height={35} borderRadius={4}/>
                <Stack>
                    <Text cursor={'pointer'} _hover={{textDecoration: 'underline'}} fontWeight={500} fontSize={'xs'} color={'gray.500'} onClick={() => navigate(`/market/${market.contractAddress}`)}>{market.title}</Text>
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Image borderRadius={4} src={randomAvatar} height={25}/><Text fontSize={'sm'} fontWeight={600}>{formatAddress(activity.taker)}</Text> <Text fontSize={'xs'}> bought </Text>
                        {activity.taker_side === '0' ? (<Text fontWeight={600} color={greenColor}>Happens</Text>) : (<Text fontWeight={600} color={redColor}>Not</Text>)}<Text>at {activity.price / 100}¢ (${totalAmount.toFixed(2)})</Text>)
                    </Stack>
                    
                </Stack>
            </Stack>
        )
    } else if (activity.hasOwnProperty('event_name') && activity.hasOwnProperty('market')) {
        const market = markets.find((x) => getChecksumAddress(x.contractAddress) === getChecksumAddress(activity.market));
        const randomAvatar = getRandomImageFromAddress(activity.caller)

        const totalAmount = getBalanceNumber(activity.amount ,6);
        if(activity.event_name === 'SharesMinted') {
            return (
                <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <Image borderRadius={4} src={market.image} height={35} />
                    <Stack>
                        <Text cursor={'pointer'} _hover={{textDecoration: 'underline'}} fontWeight={500} fontSize={'xs'} color={'gray.500'} onClick={() => navigate(`/market/${market.contractAddress}`)}>{market.title}</Text>
                        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <Image borderRadius={4} src={randomAvatar} height={25}/><Text fontSize={'sm'} fontWeight={600}>{formatAddress(activity.caller)}</Text> <Text fontSize={'xs'}> minted </Text>
                            <Text fontWeight={600} color={greenColor}>{totalAmount.toFixed(2)}</Text><Text>shares for</Text><Text fontWeight={600} color={greenColor}>${totalAmount.toFixed(2)}</Text>
                        </Stack>
                        
                    </Stack>
                </Stack>
            )    
        }
    } else if (activity.hasOwnProperty('order_id') && activity.hasOwnProperty('amount') && activity.hasOwnProperty('price') && activity.hasOwnProperty('orderbook')) {
        const market = markets.find((x) => getChecksumAddress(x.orderbookAddress) === getChecksumAddress(activity.orderbook));

        const randomAvatar = getRandomImageFromAddress(activity.maker);
        const totalAmount = getBalanceNumber(new BigNumber(activity.price / 100).multipliedBy(new BigNumber(activity.amount)).dividedBy(100).toString(), 6);

        return (
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Image src={market.image} height={35} borderRadius={4}/>
                <Stack>
                    <Text cursor={'pointer'} _hover={{textDecoration: 'underline'}} fontWeight={500} fontSize={'xs'} color={'gray.500'} onClick={() => navigate(`/market/${market.contractAddress}`)}>{market.title}</Text>
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Image borderRadius={4} src={randomAvatar} height={25}/><Text fontSize={'sm'} fontWeight={600}>{formatAddress(activity.maker)}</Text> <Text fontSize={'xs'}> inserted new order to </Text>
                        {activity.side === '0' ? (<Text fontWeight={600} color={greenColor}>Buy</Text>) : (<Text fontWeight={600} color={redColor}>Sell</Text>)} 
                        {activity.asset === '0' ? (<Text fontWeight={600} color={greenColor}>Happens</Text>) : (<Text fontWeight={600} color={redColor}>Not</Text>)}<Text>at {activity.price / 100}¢ (${totalAmount.toFixed(2)})</Text>)
                    </Stack>
                    
                </Stack>
            </Stack>
        )
    }
}

export default RecentActivities;