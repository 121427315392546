import { Menu, MenuButton, MenuList, MenuItem, Image, Button, useDisclosure, Text, Stack } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useState, useRef, useContext } from 'react';
import Dialog from '../Dialog';

export default function Networks() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    return (
        <Menu>
            <Dialog title={'Alpha Version Disclaimer'} message={'Expectium is currently running only on mainnet with alpha stage. Any problem with smart contracts or network can cause loss of funds. Expectium smart contracts are not audited yet and there can be bugs in the code which can also cause loss of funds.'} 
                isOpen={isOpen} onOpen={onOpen} onClose={onClose} cancelRef={cancelRef}
            />
            
            <MenuButton as={Button} rightIcon={<InfoOutlineIcon />} onClick={() => onOpen()}>
            <Stack direction={'row'} alignItems={'center'}>
                <Image
                        boxSize='1.5rem'
                        borderRadius='full'
                        src='/images/icons/starknet_flat_darkbg.svg'
                        alt='Fluffybuns the destroyer'
                        mr='12px'
                    />
                                <Text fontSize={'sm'}>{'Starknet Mainnet'}</Text>
            </Stack>

            </MenuButton>
        </Menu>
    )
}