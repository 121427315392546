import { Box, Stack, Button, Container, Icon, Grid, useColorModeValue, Heading } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { HiPaperAirplane, HiSparkles, HiFire, HiViewGrid  } from "react-icons/hi";


import { useState } from "react";
import MarketBox from "./MarketBox";
import { useMarkets } from "../../../state/hooks";

const Markets = ({resolved}) => {
    const [selected, setSelected] = useState(-1);
    const markets = useMarkets();

    const activeMarkets = markets.filter((x) => x.hasOwnProperty('liveMarketData') && !x.liveMarketData?.isResolved)

    const categories = activeMarkets?.map((x) => x.category);
    const uniqueCategories = ['All', 'Top', 'New', 'Popular', ...new Set(categories)];

    const listedMarkets = selected === -1 ? activeMarkets : activeMarkets.filter((x) => x.categoryFilters?.indexOf(uniqueCategories[selected]) > -1)
    const resolvedMarkets = markets.filter((x) => x.hasOwnProperty('liveMarketData') && x.liveMarketData?.isResolved)

    const activeTabColorScheme = useColorModeValue('teal', 'teal')

    // const categories = ['All', 'Top', 'New', 'Popular', 'Crypto', 'Starknet', 'Politics', 'Forex']
    const icons = [
        <HiViewGrid  />,
        <HiPaperAirplane />,
        <HiSparkles />,
        <HiFire />
    ]

    function changeFilteredCategory(index) {
        setSelected(index)
    }

    return (
        <Container maxW={'8xl'}>
        {!resolved ? (            
            <Stack display={['none', 'flex']} flexDirection={'row'} spacing={4}>
                {uniqueCategories.map((cat, index) => (index < 4 ? (
                            <Button leftIcon={icons[index]} borderRadius={'24px'} padding={5}
                                onClick={() => changeFilteredCategory(index)} 
                                colorScheme={selected === index ? activeTabColorScheme : 'gray'}>
                                {cat}
                            </Button>
                        ) : (
                            <Button borderRadius={'24px'} padding={5}
                                onClick={() => changeFilteredCategory(index)}
                                colorScheme={selected === index ? activeTabColorScheme : 'gray'}>
                                {cat}
                            </Button>   
                        )))}
            </Stack>) : (<Heading size={'lg'}>Resolved Markets</Heading>)}
            
            {!resolved ? (
                <Grid templateColumns={['repeat(1,1fr)','repeat(4, 1fr)']} minHeight={'500px'} padding={4}>
                            {listedMarkets.map((market) => (
                                <MarketBox market={market} />
                            ))}
                </Grid>
            ) : (
                <Grid templateColumns={['repeat(1,1fr)','repeat(4, 1fr)']} minHeight={'500px'} padding={4}>
                            {resolvedMarkets.map((market) => (
                                <MarketBox market={market} />
                            ))}
                </Grid>
            )}


        </Container>
    )
}

export default Markets;