import axios from "axios";
import { API_BASE_URL } from "../config/contants";
import BigNumber from "bignumber.js";

export const getMarketInfo = async (market) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/market/${market}/info`)
        return response.data?.data;
    } catch (ex) {
        console.error(ex);
        return {
            status : 'ERROR',
            data : {}
        };
    }
}

export const sendComment = async (account, signature, data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/comments/publish`,{
                account, signature, data
        })
        return response.data;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getComments = async (market) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/comments/${market}`)
        return response.data?.data;
    } catch(ex) {
        console.error(ex)
        return;
    }
}

export const getHomeInfo = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/home/info`)
        return response.data?.data;  
    } catch (ex) {
        console.error(ex)
    }
}

export const orderTransactions = (txns) => {
    try {
        const all = [...txns.buys, ...txns.sells];

        const sorted = all.sort((a,b) => Number(b.blockNumber) -  Number(a.blockNumber));
        return sorted.slice(0, 10);
    } catch (ex) {
        console.error(ex);
        return [];
    }
}

export const getChangeRate = (current, past) => {
    try {
        const currentBN = new BigNumber(current);
        const pastBN = new BigNumber(past);

        const side = currentBN.isGreaterThanOrEqualTo(pastBN) ? 'increase' : 'decrease';

        if(side == 'increase') {
            const rate = currentBN.dividedBy(pastBN).minus(1).multipliedBy(100);
            return {
                side, rate : rate.isNaN() ? '0.00' : rate.toFixed(2)
            }
        } else {
            const rate = pastBN.dividedBy(currentBN).minus(1).multipliedBy(100);

            return {
                side, rate : rate.isNaN() ? '0.00' : rate.toFixed(2)
            }
        }


    } catch (ex) {
        console.error(ex);
        return {
            side : 'increase',
            rate : '0.00'
        }
    }
}