import { ModalOverlay, Modal, ModalBody, ModalContent, ModalHeader, ModalFooter, Button, Text, ModalCloseButton,
    Tabs, TabList, Tab, TabPanel, TabPanels, useColorModeValue, Stack, Input, InputRightElement, InputLeftElement, InputGroup, useToast } from "@chakra-ui/react"
import { useState, useContext } from "react"
import { getBalanceNumber, getFullDisplayBalance } from "../../../utils/formatNumber";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { useBalances} from "../../../state/hooks";
import { useStarknetReact } from "@web3-starknet-react/core";
import BigNumber from "bignumber.js";
import {mintShares, mergeShares } from "../../../utils/callHelpers";
import { useAccount, useContractWrite } from "@starknet-react/core";

export default function MintMergeModal({isOpen, onClose, market}) {
    return (
            <Modal size={'xl'} isCentered isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay
                    bg='blackAlpha.500'
                    backdropFilter='blur(5px) hue-rotate(45deg)'
                    />
                    <ModalContent>
                    <ModalHeader>Mint new shares or merge existing</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Tabs isFitted  variant='unstyled'>
                            <TabList mb='1em'>
                                <Tab borderRadius={'md'} letterSpacing={1} fontWeight= '500' _selected={{ color : useColorModeValue('white', 'black'), bg: useColorModeValue('green.500', 'green.200') }}>Mint Shares</Tab>
                                <Tab borderRadius={'md'} letterSpacing={1} fontWeight= '500' _selected={{ color : useColorModeValue('white', 'black'), bg: useColorModeValue('red.500', 'red.200') }}>Merge Shares</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <MintShares market={market} />
                                </TabPanel>
                                <TabPanel>
                                    <MergeShares market={market} />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                    </ModalContent>
            </Modal>
    )
}

function MintShares({market}) {
    const { address } = useAccount(); 
    const [invest, setInvest] = useState('');
    const [onTxn, setOnTxn] = useState(false);
    const toast = useToast();
    const handleChangeInvest = (event) => setInvest(event.target.value.replace(',','.'))
    const balances = useBalances();
    const isConnected = address; 
    const { writeAsync } = useContractWrite({})

    const usdcBalance = balances.data ? balances.data.usdc : '0'

    function setMaxInvest() {
        setInvest(getBalanceNumber(usdcBalance,6));
    }

    async function sendMintShares() {
        try {
            setOnTxn(true);
            const amount = invest;
            const amountAdjusted = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(6)).toString()

            await mintShares(writeAsync, 'mainnet', market, amountAdjusted);
            toast({
                title: 'Transaction Broadcasted',
                description: `Transaction successfully broadcasted.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (ex) {
            toast({
                title: 'Error during sending transaction',
                description: ex.message? ex.message : 'Unknown error',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setOnTxn(false);
        }
    }
    return (
        <Stack alignItems={'center'}>
                                        <p>By minting shares, you will receive same amount of Happens and Not shares as your investment.</p>
                <InputGroup>
                                        <InputLeftElement
                                        pointerEvents='none'
                                        color='gray.300'
                                        fontSize='1em'
                                        children='$'
                                        />
                                            <Input 
                                            isInvalid={invest > getBalanceNumber(usdcBalance, 6)}
                                            type='number'
                                            placeholder='0' 
                                            value={invest} 
                                            onChange={handleChangeInvest} /> {/* Burda value handleda bi problem var error veriyo */}
                                            <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setMaxInvest()}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                    </InputGroup>
                                    {isConnected ? (
                                    <Text fontWeight={400} mb={2} fontSize={'sm'}>
                                        Available : {getFullDisplayBalance(usdcBalance,6,2)} $
                                    </Text>
                                ) : (<></>)}

                                <Button isLoading={onTxn}
                                        loadingText="Broadcasting Transaction"
                                        isDisabled={onTxn || invest > getBalanceNumber(usdcBalance, 6) || invest == '0' || invest == 0} 
                                        colorScheme="green" 
                                        size={'md'} width={'50%'} 
                                        onClick={() => sendMintShares()}>Mint</Button>
        </Stack>
    )
}

function MergeShares({market}) {

    const { address } = useAccount();
    const [shares, setShares] = useState('');
    const [onTxn, setOnTxn] = useState(false);
    const toast = useToast();
    const handleChangeShares = (event) => setShares(event.target.value.replace(',','.'))
    const isConnected = address; 
    const { writeAsync } = useContractWrite({})

    const userBalances = market.userData ? market.userData?.balances : {happens : '0', not : '0'};
    console.log(userBalances)

    function setMaxShares() {
        const { happens, not } = userBalances;
        if(new BigNumber(happens).isLessThanOrEqualTo(new BigNumber(not))) {
            setShares(getBalanceNumber(happens, 6));
        } else {
            setShares(getBalanceNumber(not, 6));
        }
    }

    function getAvailableBalance() {
        const { happens, not } = userBalances;
        if(new BigNumber(happens).isLessThanOrEqualTo(new BigNumber(not))) {
            return (getBalanceNumber(happens, 6));
        } else {
            return (getBalanceNumber(not, 6));
        }
    }

    async function sendMergeShares() {
        try {
            setOnTxn(true)
            const amount = shares;
            const amountAdjusted = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(6)).toString()
    
            await mergeShares(writeAsync, 'mainnet', market, amountAdjusted);
            toast({
                title: 'Transaction Broadcasted',
                description: `Transaction successfully broadcasted.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (ex) {
            toast({
                title: 'Error during sending transaction',
                description: ex.message? ex.message : 'Unknown error',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setOnTxn(false)
        }
    }

    return (
        <Stack alignItems={'center'}>
                                        <p>By merging shares, you can convert 1 Happens and 1 Not shares to 1 USDC anytime.</p>
                <InputGroup>
                                            <Input 
                                            isInvalid={shares > getBalanceNumber(userBalances.happens, 6) || shares > getBalanceNumber(userBalances.not, 6)}
                                            type='number'
                                            placeholder='0' 
                                            value={shares} 
                                            onChange={handleChangeShares} /> {/* Burda value handleda bi problem var error veriyo */}
                                            <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setMaxShares()}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                    </InputGroup>
                                    {isConnected ? (
                                    <>
                                        <Stack direction={'row'}>
                                            <Text fontWeight={400} mb={2} fontSize={'xs'}>
                                                Happens : {getBalanceNumber(userBalances.happens, 6)}
                                            </Text>
                                            <Text fontWeight={400} mb={2} fontSize={'xs'}>
                                                Not : {getBalanceNumber(userBalances.not, 6)}
                                            </Text>
                                        </Stack>
                                    </>
                                ) : (<></>)}

                                <Button isLoading={onTxn}
                                        loadingText="Broadcasting Transaction"
                                        isDisabled={shares > getBalanceNumber(userBalances.happens, 6) || shares > getBalanceNumber(userBalances.not, 6) || shares == '0' || shares == 0} 
                                        colorScheme="red" size={'md'} width={'50%'} 
                                        onClick={() => sendMergeShares()}>Merge</Button>
        </Stack>
    )
}