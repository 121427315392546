import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Skeleton
  } from '@chakra-ui/react'
import { useMarketFromContract } from '../../../state/hooks'
import { formatNumber } from '../../../utils/calculateOdds';
import { getChangeRate } from '../../../utils/APIHelpers';
import { getBalanceNumber } from '../../../utils/formatNumber';

export default function Statistics({ contractAddress }) {
    const marketData = useMarketFromContract(contractAddress);
    const isLoaded = marketData.hasOwnProperty('liveMarketData');
    const isAPILoaded = marketData.hasOwnProperty('apiMarketData');

    return (
        <StatGroup mt={5} mb={5} textAlign={'center'}>
            <Stat>
                <StatLabel>Liquidity</StatLabel>
                <StatNumber>
                    <Skeleton isLoaded={isLoaded}>
                        ${isLoaded ? getBalanceNumber(marketData.liveMarketData.liquidity, 6).toFixed(2) : '000.00'}    
                    </Skeleton>            
                </StatNumber>
                <StatHelpText>
                Based on pooled collaterals
                </StatHelpText>
            </Stat>

            <Stat>
                <StatLabel>Volume</StatLabel>
                <StatNumber>
                    <Skeleton isLoaded={isAPILoaded}>
                        ${isAPILoaded ? getBalanceNumber(marketData.apiMarketData?.info?.volume, 6).toFixed(2) : '000.00'}    
                    </Skeleton>            
                </StatNumber>
                <StatHelpText>
                All time
                </StatHelpText>
            </Stat>

            <Stat>
                <StatLabel>Trades</StatLabel>
                <StatNumber>
                    <Skeleton isLoaded={isAPILoaded}>
                        {isAPILoaded ? marketData.apiMarketData?.info?.tradeCount : '0'}    
                    </Skeleton>            
                </StatNumber>
                <StatHelpText>
                count
                </StatHelpText>
            </Stat>
        </StatGroup>
    )
}