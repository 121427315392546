import { Box, Text, Image, Stack, Button, Heading, useColorModeValue } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

export default function TrendingMarket({bgColor, market}) {

    const buttonTextColor = useColorModeValue('blackAlpha.900', 'whiteAlpha.900')
    const navigate = useNavigate();

    return (
        <Box backgroundImage={`linear-gradient(to right, ${bgColor.start} , ${bgColor.end})`} 
            width={{base: '100%', md: '50%', xl: '24%'}} height={'140px'} borderRadius={'12px'}
            marginTop={'0rem !important'} boxShadow='md'
            _hover={{boxShadow: '2xl'}} transition={'0.3s'}>
        <Stack height={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} padding={4}>
            <Stack width={'70%'}>
            <Heading size={'sm'}>
                {market.homebar.title}
            </Heading>
            <Text fontSize={'12px'} fontWeight={500}>
                {market.homebar.desc}
            </Text>
            <Button colorScheme={'whiteAlpha'} width={'100px'} size={'xs'} textColor={buttonTextColor}
                    onClick={() => navigate(`/market/${market.contractAddress}`)}>
                Check Market
            </Button>
            </Stack>
            <Image borderRadius={'md'} boxSize={'90px'} src={market.homebar.img} alt="Event Avatar" />

        </Stack>

        </Box>
    )
}