import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    Flex,
    Tag,
    useColorModeValue,
    useColorMode,
  } from '@chakra-ui/react';

  import { WEBSITE_BASE } from '../../config/contants';
  import logoWhite from '../../assets/logo/logo_white.png';
  import logoBlack from '../../assets/logo/logo_black.png';

  const Logo = (props) => {
    const { colorMode } = useColorMode();
    return (
        <img src={colorMode === 'light' ? logoBlack : logoWhite} height={32} width={200}/>
    );
  };
  
  const ListHeader = ({ children }) => {
    return (
      <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
        {children}
      </Text>
    );
  };

  /*
    TAG EKLEME

                  <Stack direction={'row'} align={'center'} spacing={2}>
                <Link href={'#'}>Features</Link>
                <Tag
                  size={'sm'}
                  bg={useColorModeValue('green.300', 'green.800')}
                  ml={2}
                  color={'white'}>
                  New
                </Tag>
              </Stack>
  */
  
  export default function Footer() {
    return (
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container as={Stack} maxW={'6xl'} py={10}>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
            <Stack align={'flex-start'}>
              <ListHeader>Learn</ListHeader>
              <Link href={'https://docs.expectium.io/about/what-is-expectium'}>Overview</Link>
              <Stack direction={'row'} align={'center'} spacing={2}>
                <Link href={'https://docs.expectium.io/about/what-is-expectium'}>Features</Link>
                <Tag
                  size={'sm'}
                  bg={useColorModeValue('green.300', 'green.800')}
                  ml={2}
                  color={'white'}>
                  New
                </Tag>
              </Stack>
              <Link href={'https://docs.expectium.io/about/what-is-an-information-market'}>Tutorials</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Markets</ListHeader>
              <Link href={'https://docs.expectium.io/getting-started/information-markets'}>Information Markets</Link>
              <Link href={'https://docs.expectium.io/getting-started/share-nfts'}>Share NFTs</Link>
              <Link href={'https://docs.expectium.io/getting-started/revenue-distribution'}>Revenue Model</Link>
            </Stack>
            <Stack align={'flex-start'}>
              <ListHeader>Community</ListHeader>
              <Link href={'https://twitter.com/expectiumio'}>Twitter</Link>
              <Link href={'https://t.me/expectiumprotocol'}>Telegram</Link>
              <Link href={'https://discord.gg/TUR8ycEF'}>Discord</Link>
            </Stack>
          </SimpleGrid>
        </Container>
        <Box py={10}>
          <Flex
            align={'center'}
            _before={{
              content: '""',
              borderBottom: '1px solid',
              borderColor: useColorModeValue('gray.200', 'gray.700'),
              flexGrow: 1,
              mr: 8,
            }}
            _after={{
              content: '""',
              borderBottom: '1px solid',
              borderColor: useColorModeValue('gray.200', 'gray.700'),
              flexGrow: 1,
              ml: 8,
            }}>
            <Logo />
          </Flex>
          <Text pt={6} fontSize={'sm'} textAlign={'center'}>
            © 2023 Expectium. All rights reserved
          </Text>
        </Box>
      </Box>
    );
  }
