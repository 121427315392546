
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useState, useContext } from 'react';



import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';

import Home from './views/Home';
import Markets from './views/Markets';
import Market from './views/Market';
import Statistics from './views/Statistics';
import Profile from './views/Profile';
import Shares from "./views/Shares";
import { useFetchMarketPublicData } from './state/hooks';


// import starknet.js

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/markets/resolved", // TODO MARKETID DEĞISTIR ILK SIRALAMA DONSUN
    element: <Home resolved/>
  },
  {
    path: "/market/:marketId",
    element: <Market />
  },
  {
    path: "/statistics/:type",
    element: <Statistics />
  },
  {
    path: "/profile",
    element: <Profile />
  },
  {
    path: "/shares",
    element: <Shares />
  }
]);



function App() {
  useFetchMarketPublicData()
  return ( // TODO Provideri değiştir Appi dışarı al yoksa redux hatası devam eder https://stackoverflow.com/questions/60329421/usedispatch-error-could-not-find-react-redux-context-value-please-ensure-the
            <div className="App">
              <Navbar router={router}/>
              <RouterProvider router={router}/>
              <Footer />
            </div>
  );
}

export default App;
