import { Card, CardHeader, CardBody, CardFooter, 
    Text, Flex, IconButton, Box, Heading, Avatar,
    Image, Button, Tabs, TabList, TabPanel, TabPanels, Tab, 
    useColorModeValue, Stack, Popover, PopoverTrigger, Input, InputGroup, 
    InputLeftElement, InputRightElement, Grid, GridItem, Divider, useDisclosure, Tooltip } from '@chakra-ui/react'
import { ArrowBackIcon, SettingsIcon, WarningTwoIcon, InfoOutlineIcon } from '@chakra-ui/icons'
import { useState, useContext } from 'react'

import TradeSettings from './TradeSettings';
import ConnectWallet from '../../../components/Wallet/ConnectWallet';
import { useStarknetReact } from '@web3-starknet-react/core';
import { useMarketFromContract, useBalances } from '../../../state/hooks';
import { getFullDisplayBalance, getBalanceNumber } from '../../../utils/formatNumber';
import { convertShares } from '../../../utils/callHelpers';
import { NetworkContext } from '../../../contexts/NetworkContext';
import { calculateUsdAmount } from '../../../utils/calculateSlippage';
import BigNumber from 'bignumber.js';
import { getPrices } from '../../../utils/calculateOdds';
import { useAccount, useContractWrite } from '@starknet-react/core';

export default function ConvertBox({ contractAddress }) {
    const { address } = useAccount();
    const { writeAsync } = useContractWrite({})

    const { network } = useContext(NetworkContext);
    const market = useMarketFromContract(contractAddress);
    // TODO user bakiye çek inputta kontrol et
    const [outcome, setOutcome] = useState(false); // false -> happens - true -> not
    const [outcomeInput, setOutcomeInput] = useState('');
    const [tabIndex, setTabIndex] = useState(0) // buy sell tab selector
    const handleOutcomeInputChange = (event) => setOutcomeInput(event.target.value.replace(',','.'))

    const liquidityModal = useDisclosure();
    
    const balances = useBalances();

    const isLoadedUser = market.hasOwnProperty('userData');
    const userOutcomeBalances = isLoadedUser ? market.userData.balances : { happens: '0', not: '0'};

    console.log(market)


    const inactiveButtonColors = useColorModeValue('gray.100', 'gray.600');
    const happensButtonColors = useColorModeValue('green.300', 'green.500');
    const notButtonColors = useColorModeValue('red.300', 'red.500');

    const isConnected = address; 

    function changeOutcome(value) {
        setOutcome(value);
        setOutcomeInput('')
    }

    function getOutcomeRatioNumber(side) {
        if(market.liveMarketData?.isResolved) {
            if(side == 0) {
                return new BigNumber(market.liveMarketData?.resolve_rate?.happens).dividedBy(10000).toFixed(2);
            } else {
                return new BigNumber(market.liveMarketData?.resolve_rate?.not).dividedBy(10000).toFixed(2);
            }
        } else {
            return "0"
        }
    }

    function setMaxOutcome() {
        setOutcomeInput(getBalanceNumber(userOutcomeBalances[outcome ? 'not' : 'happens'], 6))
    }

    function renderUserOutcomeBalance(side) {
        if(side) {
            // not
            return market.userData ? market.userData?.balances?.user_not : 0; 
        } else {
            // happens
            return market.userData ? market.userData?.balances?.user_happens: 0; 
        }
    }

    async function submitConvertShares() {
        const currentAmount = outcomeInput;
        const amountAdjusted = new BigNumber(currentAmount).multipliedBy(new BigNumber(10).pow(6)).toString()
        await convertShares(writeAsync, network, market, outcome ? '1' : '0', amountAdjusted)
    }
    

    return (
        <Card maxW='md' boxShadow={'xl'}>
            <CardHeader>
                <Tabs isFitted variant='unstyled'>
                    <TabList mb='1em'>
                        <Tab borderRadius={'md'} letterSpacing={1} fontWeight= '500' _selected={{ color : useColorModeValue('white', 'black'), bg: useColorModeValue('blue.500', 'blue.200')}}>Convert Shares</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                        <Text pb={5} fontSize={'sm'}>
                            This market is resolved. You can convert your shares with ratio below. Do not forget to cancel your active orders.
                        </Text>
                        <Stack>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Text fontWeight={500}>
                                    Outcome
                                </Text>
                            </Stack>

                            <Stack direction={'row'}>
                                <Button fontSize={'xs'} _hover={{backgroundColor : outcome == false ? happensButtonColors : inactiveButtonColors}} onClick={() => changeOutcome(false)} backgroundColor={ outcome == false ? happensButtonColors : inactiveButtonColors} flex={1}>Happens ${getOutcomeRatioNumber(0)}</Button>
                                <Button fontSize={'xs'} _hover={{backgroundColor : outcome == true ? notButtonColors : inactiveButtonColors}} onClick={() => changeOutcome(true)} backgroundColor={ outcome == true ? notButtonColors : inactiveButtonColors} flex={1}>Not ${getOutcomeRatioNumber(1)}</Button>
                            </Stack>
                            <Text fontWeight={500} mt={2}>
                                    Shares
                            </Text>
                            <Stack>
                                <InputGroup>
                                    <Input
                                    isInvalid={outcomeInput > getBalanceNumber(renderUserOutcomeBalance(outcome), 6)}
                                    type='number'
                                    placeholder='0' 
                                    value={outcomeInput}
                                    onChange={handleOutcomeInputChange}  />
                                    <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setMaxOutcome()}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                </InputGroup>
                                {isConnected ? (
                                    <Stack>
                                    <Text fontWeight={400} fontSize={'sm'}>
                                        Available : {getBalanceNumber(userOutcomeBalances[outcome ? 'not' : 'happens'], 6)}
                                    </Text>
                                    <Text fontWeight={400} mb={2} fontSize={'sm'}>
                                        You Will Receive : ${Number(getOutcomeRatioNumber(outcome ? 1 : 0)) * Number(getBalanceNumber(userOutcomeBalances[outcome ? 'not' : 'happens'], 6))}
                                    </Text>
                                    </Stack>
                                ) : (<></>)}
                            </Stack>
                            {isConnected ? (<Button isDisabled={outcomeInput > getBalanceNumber(renderUserOutcomeBalance(outcome), 6) || outcomeInput == 0 || outcomeInput == ''} colorScheme='blue' onClick={() => submitConvertShares()}>Approve & Convert</Button>) : (<ConnectWallet />)}
                            
                        </Stack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </CardHeader>
            </Card>
    )
}