import { ReactNode } from 'react';
import { WEBSITE_BASE } from '../../config/contants';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Popover,PopoverTrigger, PopoverContent, Text
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, ChevronRightIcon, Icon } from '@chakra-ui/icons';
import ConnectWallet from '../Wallet/ConnectWallet';
import { Link as RouterLink } from 'react-router-dom';
import logoWhite from '../../assets/logo/logo_white.png';
import logoBlack from '../../assets/logo/logo_black.png';
import Networks from './Network';

// TODO mobil navbar

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}>
    {children}
  </Link>
);

const NAV_ITEMS = [
    {
      label: 'Markets',
      children: [
        {
          label: 'Live Markets',
          href: '/',
          subLabel: 'All existing markets'
        },
        {
          label: 'Resolved Markets',
          href: '/markets/resolved',
          subLabel: 'Resolved markets'
        }
      ]
    }, /*
    {
      label: 'Statistics',
      children: [
        {
          label: 'Protocol Data',
          subLabel: 'Expectium protocol overview',
          href: '/statistics/protocol',

        },
        {
          label: 'Markets History',
          subLabel: 'Analyze past markets',
          href: '/statistics/history',

        },
        {
          label: 'Upcoming Events',
          subLabel: 'Choose best up-coming event for you',
          href: '/statistics/upcoming',

        },
      ],
    }, */
    {
      label: 'Shares',
      href: '/shares',

    },
    /*
    {
      label: 'Dashboard',
      href: '/profile',

    }, */
    {
      label: 'Learn',
      href: 'https://docs.expectium.io',
      isExternal: true,
    },
  ];

export default function Navbar({router}) {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();

    function handleLink(navItem) {
      if(navItem.href && navItem.isExternal) {
        console.log('external')
        window.location.replace(navItem.href)
      } else if(navItem.href) {
        router.navigate(navItem.href);
      }
    }
  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} maxWidth={'100%'}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <Stack spacing={10} direction={'row'} alignItems={'center'}>
          <Box display={['none', 'flex']}>
          <Link onClick={() => router.navigate('/')}>
            <img src={colorMode === 'light' ? logoBlack : logoWhite} width={150}/>
          </Link>

          </Box>
          <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                onClick={() => handleLink(navItem)}
                fontSize={['xs', 'sm']}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child}  router={router}/>
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
    </Stack>
          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={[2,3]} alignItems={'center'}>
                    <Stack display={['none', 'flex']}>
                    <Networks />
                    </Stack>
              <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>
              <ConnectWallet></ConnectWallet>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}

const DesktopSubNav = ({ label, href, subLabel, router }) => {
    return (
      <Link
        onClick={() => router.navigate(href)}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('green.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text 
              transition={'all .3s ease'}
              _groupHover={{ color: 'green.400' }}
              fontWeight={500}>
                {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'green.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
  };