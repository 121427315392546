import { Button, Skeleton, Stack, Text, Textarea, useToast, Image } from "@chakra-ui/react";
import ConnectWallet from "../../../components/Wallet/ConnectWallet";
import { useAccount, useSignTypedData } from "@starknet-react/core";
import { useEffect, useState } from "react";
import { getComments, sendComment } from "../../../utils/APIHelpers";
import useRefresh from "../../../hooks/useRefresh";
import { getRandomImageFromAddress } from "../../../utils/imageUtils";
import { formatAddress } from "../../../utils/formatAddress";
import { secondsAgo } from "../../../utils/timeUtils";
import { shortString } from "starknet";

export default function Comments({market}) {
    let [comment, setComment] = useState('')
    const [isOnSigning, setIsOnSigning] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { slowRefresh } = useRefresh();
    const [comments, setComments] = useState([]);
    const { address, account } = useAccount();
    const toast = useToast();

    const { signTypedDataAsync } = useSignTypedData({})

    useEffect(() => {
        async function loadComments() {
            const response = await getComments(market);
            console.log(response)
            setComments(response)
            setLoaded(true)
        }

        loadComments();
    }, [slowRefresh, address, market])

    async function signMessage(address) {
        try {
            setIsOnSigning(true)

            const data = {
                types: {
                  StarkNetDomain: [
                    { name: "name", type: "felt" },
                    { name: "version", type: "felt" },
                    { name: "chainId", type: "felt" },
                  ],
                  Message: [
                    { name: "sender", type: "felt" },
                    { name: "message", type: "string" },
                    { name: "market", type: "felt" },
                  ],
                },
                primaryType: "Message",
                domain: {
                  name: "Expectium Comment",
                  version: "1",
                  chainId: shortString.encodeShortString("SN_MAIN"),
                },
                message: {
                  sender: address,
                  message: comment,
                  market: market
                },
              };
            const signedMessage = await signTypedDataAsync(data);
            await sendComment(address, signedMessage, data)

            toast({
                title: 'Comment Published',
                description: `Comment successfully published.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (ex) {
            console.error(ex)
            toast({
                title: 'Error during sending signature',
                description: ex.message? ex.message : 'Unknown error',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setIsOnSigning(false)
        }
    }

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setComment(inputValue)
    }
    return (
        <Stack>
            <Stack><Text fontSize={'sm'} color={'gray.500'}>Comment lengths limited due starknet signing system.</Text>
                {address ? (
                    <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    
                    <Textarea
                        value={comment}
                        onChange={handleInputChange}
                        placeholder='Add a comment'
                        size='sm'
                        borderRadius={10}
                    />
                    <Button onClick={() => signMessage(address)} 
                            isDisabled={comment.length < 8}
                            disabled={!account} colorScheme="blue" 
                            isLoading={isOnSigning} loadingText='Signing' size={'md'}>Publish</Button>
                    </Stack>
                ): (<ConnectWallet />)}
            </Stack>
            <Stack spacing={6} maxHeight={'300px'} overflowY={'auto'}>
                <Skeleton isLoaded={loaded}>
                    {comments.map((comm) => (
                        <CommentBox comment={comm} />
                    ))}
                </Skeleton>
            </Stack>
        </Stack>
    )
}

function CommentBox({comment}) {
    const avatar = getRandomImageFromAddress(comment.sender)
    return (
        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} pt={2}>
            <Image borderRadius={8} src={avatar} width={50} height={50} />
            <Stack spacing={-2}>
                <Text fontWeight={600}>
                    {formatAddress(comment.sender)}
                </Text>
                <Text fontWeight={400} fontSize={'sm'}>
                        {comment.message}
                </Text>
                <Text fontSize={'xs'} color={'gray.500'}>
                    {secondsAgo(comment.time)}
                </Text>
            </Stack>
        </Stack>

    )
}