import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux'
import BigNumber from "bignumber.js";
import useRefresh from '../hooks/useRefresh'
import { fetchMarketsPublicDataAsync, fetchMarketsApiDataAsync } from "./markets";

export const useFetchMarketPublicData = () => {
    const dispatch = useDispatch();
    const { slowRefresh } = useRefresh()
    useEffect(() => {
        dispatch(fetchMarketsPublicDataAsync())
        dispatch(fetchMarketsApiDataAsync())
    }, [dispatch, slowRefresh])
}

export const useMarkets = () => {
    const farms = useSelector((state) => state.markets.data)
    return farms
}

export const useMarketFromContract = (contract) => {
    const market = useSelector((state) => state.markets.data.find((f) => f.contractAddress === contract))
    return market
}

export const useMarketUser = (contract) => {
    const market = useMarketFromContract(contract);

    return {
        happens: market.userData ? new BigNumber(market.userData.user_happens) : new BigNumber(0),
        not: market.userData ? new BigNumber(market.userData.user_not) : new BigNumber(0),
        liquidity: market.userData ? new BigNumber(market.userData.user_liquidity) : new BigNumber(0),
    }
}

export const useBalances = () => {
    const balances = useSelector((state) => state.user);
    return balances;
}