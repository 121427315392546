import markets from "../../config/markets";
import { callContractWithoutParams,  getAMMMarketResolves, getMarketsDataWithOrderbooks, getMarketData } from "../../utils/callHelpers";
import AMMMarketABI from '../../config/ABI/MarketABI.json';
import OrderbookMarketABI from '../../config/ABI/MarketOrderbook.json';
import BigNumber from "bignumber.js";
import { aggregateOrders, unpackOrder, unpackTrade, sumOrderAmounts } from "../../utils/orderHelpers";
import BN from "bn.js";
import { CallData } from "starknet";

// TODO: try catch ekle, cors verince çöküyor.

const fetchOrderbookMarkets = async () => {
    const orderbookMarkets = markets.filter((x) => x.version === 'orderbook');
    console.log(orderbookMarkets)

    const data = await Promise.all(
        orderbookMarkets.map(async (marketConfig) => {
            try {
                const { contractAddress, network, orderbookAddress } = marketConfig;
                const aggregatedMarketData = await getMarketData(contractAddress, orderbookAddress, network);
                const happensResolveRate = aggregatedMarketData.happens_resolve;
                const notResolveRate = aggregatedMarketData.not_resolve;
    
                const collateral = aggregatedMarketData.collateral_amount;
                
                const happens_buy_orders = aggregatedMarketData.orders.happens_buy;
                const happens_sell_orders = aggregatedMarketData.orders.happens_sell;
                const not_buy_orders = aggregatedMarketData.orders.not_buy;
                const not_sell_orders = aggregatedMarketData.orders.not_sell;
    
                const unpacked_happens_buy_orders = (happens_buy_orders.map(x => unpackOrder(new BN(x).toString())))
                const unpacked_happens_sell_orders = (happens_sell_orders.map(x => unpackOrder(new BN(x).toString())))
                const unpacked_not_buy_orders = (not_buy_orders.map(x => unpackOrder(new BN(x).toString())))
                const unpacked_not_sell_orders = (not_sell_orders.map(x => unpackOrder(new BN(x).toString())))
                
                const best_happens_sell = unpacked_happens_sell_orders.length > 0 ? [...unpacked_happens_sell_orders][0] : {price : '0', amount: '0', date: '0', status: '0'};
                const best_not_sell = unpacked_not_sell_orders.length > 0 ? [...unpacked_not_sell_orders][0] : {price : '0', amount: '0', date: '0', status: '0'};
                return {
                    ...marketConfig,
                    orders: {
                        happens: {
                            buy : aggregateOrders(unpacked_happens_buy_orders),
                            total_buy: sumOrderAmounts(unpacked_happens_buy_orders),
                            sell : aggregateOrders(unpacked_happens_sell_orders),
                            total_sell: sumOrderAmounts(unpacked_happens_sell_orders),
                            best_sell: best_happens_sell.price
                        },
                        not : {
                            buy : aggregateOrders(unpacked_not_buy_orders),
                            total_buy: sumOrderAmounts(unpacked_not_buy_orders),
                            sell : aggregateOrders(unpacked_not_sell_orders),
                            total_sell: sumOrderAmounts(unpacked_not_sell_orders),
                            best_sell: best_not_sell.price
                        }
                    },
                    last_trades: [],
                    collateralized: new BigNumber(collateral).dividedBy(2).toString(), // gereksiz olabilir?
                    liquidity: new BigNumber(collateral).toString(),
                    resolve_rate: {
                        happens: new BigNumber(happensResolveRate).toString(),
                        not: new BigNumber(notResolveRate).toString()
                    },
                    isResolved: new BigNumber(happensResolveRate).plus(new BigNumber(notResolveRate)).isGreaterThan(new BigNumber(0))
                }
            } catch (ex) {
                console.error(ex)
            }
        })
    )
    return data;
}

export { fetchOrderbookMarkets };