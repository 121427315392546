import BigNumber from 'bignumber.js';
import markets from '../config/markets';
import { getChecksumAddress } from 'starknet';

export const getImageFromOrderbook = (book) => {
    const market = markets.find((x) => x.orderbookAddress === book);
    return market.image;
}

export const getMarketFromOrderbook = (book) => {
    const market = markets.find((x) => x.orderbookAddress === book);
    return market;
}

export const getMarketFromMarket = (marketx) => {
    const market = markets.find((x) => getChecksumAddress(x.contractAddress) === getChecksumAddress(marketx));
    return market;
}

const avatars = ['https://bit.ly/dan-abramov', 'https://bit.ly/prosper-baba', 'https://bit.ly/sage-adebayo', 
                'https://bit.ly/code-beast', 'https://bit.ly/kent-c-dodds', 'https://bit.ly/ryan-florence',
                'https://bit.ly/code-beast', 'https://bit.ly/kent-c-dodds', 'https://bit.ly/ryan-florence',
                'https://bit.ly/code-beast', 'https://bit.ly/kent-c-dodds', 'https://bit.ly/ryan-florence',
                'https://bit.ly/code-beast', 'https://bit.ly/kent-c-dodds', 'https://bit.ly/ryan-florence',
                'https://bit.ly/code-beast']

export const getRandomImageFromAddress = (address) => {
    const lastLetter = address.charAt(address.length -1);
    const letterDec = new BigNumber(lastLetter, 16).toNumber();

    return avatars[letterDec]
}