export default {
    marketFactory : {
        testnet : '0x06cf4c02e55531f6ddd348eb61dba844e7024f3f29b6c5bb2f19fcfeff5e162e',
        mainnet : '0x01a8b9b6d3e7216293040f64474b1f340d9ac4cb28b66ccd9c3f161cf1a1db97'
    },
    distributor: {
        testnet: '',
        mainnet: '0x03b910242e46ac8a1b930aa8b1270275843031d78c2d6dc26b1e242b84ff21c9',
    },
    shares: {
        testnet: '',
        mainnet: '0x05b5b1b7b6f683cf5c765cc5599fc05c16fddcfbe14846b6e2ec5ba9e3848b34',
    },
    forecasts : {
        testnet: '0x03ae4312af0f32cd12e589774514235bd3628f2d095c769b57c1baa5913f20b6',
        mainnet: ''
    },
    usdc : {
        testnet: '0x005a643907b9a4bc6a55e9069c4fd5fd1f5c79a22470690f75556c4736e34426',
        mainnet: '0x053c91253bc9682c04929ca02ed00b3e423f6710d2ee7e0d5ebb06f3ecf368a8'
    },
    ethereum : {
        testnet: '',
        mainnet: '0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7'
    },
    multicall: {
        testnet : '0x05324bb5be085de1d930a638c13ccc91dc36895ccf90605358248c2926b33329',
        mainnet : '0x0255fa8b6771d9a690cc5a4dd0791742dd595fc6b27c60942c60b7a5e7c789d4'
    },
    statistics: {
        testnet : '0x06ae22d384142bc82177ea299c97ca95ff4e1eb6fc64892e813b39519e34e88a',
        mainnet : ''
    }
}