import { Stack, Container, Box, Flex, Text, Heading,
    StatGroup, Stat, StatLabel, StatNumber, StatHelpText, 
    StatArrow, useColorModeValue, List, ListIcon, ListItem,
    Progress, Card, CardBody, CardFooter, CardHeader, StackDivider, Spinner, Skeleton } from '@chakra-ui/react';
import { CheckCircleIcon, CloseIcon, SpinnerIcon, CheckIcon } from '@chakra-ui/icons';
import { useEffect, useState, useContext } from 'react';
import { NetworkContext } from '../../contexts/NetworkContext';
import { useStarknetReact } from '@web3-starknet-react/core'
import { useDispatch } from 'react-redux';
import { fetchUserBalancesAsync } from '../../state/user';
import { fetchMarketsUserDataAsync } from '../../state/markets';
import { useBalances, useMarkets } from '../../state/hooks';
import useRefresh from '../../hooks/useRefresh';
import BigNumber from 'bignumber.js';
import { getFullDisplayBalance, getBalanceNumber } from '../../utils/formatNumber';
import { getAchievements } from '../../utils/achievements';

export default function Profile() {
    const [connected, setConnected] = useState(false);
    const {active, account} = useStarknetReact()
    const dispatch = useDispatch();
    const { fastRefresh } = useRefresh();
    const { network } = useContext(NetworkContext);

    useEffect(() => {
        if(account) {
            dispatch(fetchMarketsUserDataAsync(account.address))
            dispatch(fetchUserBalancesAsync(account.address, network))
        }
    }, [account, fastRefresh, network, dispatch])
    return (
        <Container maxW={'3xl'}>
            {active ? (<Connected account={account}/>) : (<NotConnected/>)}
        </Container>
    )
}

const Connected = ({account}) => {
    const markets = useMarkets();
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData')
    const userTotalTrades = isLoaded ? markets[0].userData?.balances?.userTotalTrades : '0';
    const userTotalVolume = isLoaded ? markets[0].userData?.balances?.userTotalVolume : '0';
    return (
        <Stack spacing={5} mt={5} mb={5}>
            <Heading>Financial Status</Heading>
            <Stack  direction={'row'}>
            <Stat
                px={{ base: 2, md: 4 }}
                py={2}
                shadow={'xl'}
                border={'2px solid'}
                borderColor={useColorModeValue('green.300', 'green.300')}
                rounded={'md'}
                >
                <Skeleton isLoaded={isLoaded}>
                    <StatGroup>
                        <Stat>
                            <StatLabel>Total Volume Made</StatLabel>
                            <StatNumber>$ {getBalanceNumber(userTotalVolume, 6)}</StatNumber>
                            { /* 
                                        <StatHelpText>
                                            <StatArrow type='increase' />
                                                ##%
                                        </StatHelpText>           
                            */ }

                        </Stat>
                    </StatGroup>
                </Skeleton>

            </Stat>
            <Stat
                px={{ base: 2, md: 4 }}
                py={2}
                shadow={'xl'}
                border={'2px solid'}
                borderColor={useColorModeValue('green.300', 'green.300')}
                rounded={'md'}
                >
                <Skeleton isLoaded={isLoaded}>
                    <StatGroup>
                        <Stat>
                            <StatLabel>Total Trades Count</StatLabel>
                            <StatNumber>{userTotalTrades}</StatNumber>
                            { /* 
                                        <StatHelpText>
                                            <StatArrow type='increase' />
                                                ##%
                                        </StatHelpText>           
                            */ }
                        </Stat>
                    </StatGroup>
                </Skeleton>
            </Stat>
            <Stat
                px={{ base: 2, md: 4 }}
                py={2}
                shadow={'xl'}
                border={'2px solid'}
                borderColor={useColorModeValue('red.300', 'red.300')}
                rounded={'md'}
                >
                <StatGroup>
                    <Stat>
                        <StatLabel>Position Rank</StatLabel>
                        <StatNumber>###</StatNumber>
                        { /* 
                                    <StatHelpText>
                                        <StatArrow type='increase' />
                                            ##%
                                    </StatHelpText>           
                        */ }
                    </Stat>
                </StatGroup>
            </Stat>

            </Stack>
            <Financials account={account}/>
            <Achievements />
        </Stack>
    )
}

const Achievements = () => {
    const achievements = getAchievements();
    const markets = useMarkets();
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData')

    const first_achievement_result = isLoaded ? achievements[0].status_calculator(markets) : 0;
    console.log(first_achievement_result)
    return (
        <Stack spacing={3}>
        <Heading>Achievements</Heading>
        
            {achievements.map((achievement) => (
                <Skeleton isLoaded={isLoaded}>
                <List spacing={3}>
                        <ListItem>
                            <Stack direction={'row'} alignItems={'center'}>
                            {achievement.status_calculator(markets) * 100 >= 100 ? <CheckIcon color={'green'}/> : <Spinner size={'sm'} color='orange'/>}
                             &nbsp;
                                <Text>{achievement.text} </Text>
                                <Text textAlign={'center'} fontSize={'xs'} color={'gray.400'}>{achievement.desc} </Text>
                            </Stack>
                        </ListItem>
                    <Progress hasStripe value={achievement.status_calculator(markets) * 100} size='lg' colorScheme={achievement.status_calculator(markets) * 100 >= 100 ? 'green' : 'orange'}/>
                </List>
                </Skeleton>
            ))}

        </Stack>
    )
}

const Financials = ({account}) => {
    const markets = useMarkets();
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData')

    const balances = useBalances();
    
    const userMostTradedMarket = isLoaded ? [...markets].sort((x, y) => new BigNumber(y.userData?.balances?.userMarketTrades).minus(new BigNumber(x.userData?.balances?.userMarketTrades))).slice(0, 1)[0]: { title: 'None'};
    const userMostVolumeMarkets = isLoaded ? [...markets].sort((x, y) => new BigNumber(y.userData?.balances?.userMarketVolume).minus(new BigNumber(x.userData?.balances?.userMarketVolume))).slice(0, 1)[0]: { title: 'None'};
    const usdcBalance = balances.data ? balances.data.usdc : '0'
    return (
        <Card>
            <CardHeader>
                <Heading size='md'>Account<br/><Text fontWeight={'sm'} fontSize={'sm'}>{account?.address ? account.address : ''}</Text></Heading>
            </CardHeader>

            <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                    Most traded market
                    </Heading>
                    <Skeleton isLoaded={isLoaded}>
                        <Stack direction={'column'}>
                            <Text pt='2' fontSize='sm' fontWeight={'medium'}>
                            {userMostTradedMarket.title}
                            </Text>
                            <Text pt='2' fontSize='sm' fontWeight={'bold'}>
                            Trades Count : {userMostTradedMarket.userData?.balances?.userMarketTrades}
                            </Text>
                        </Stack>
                    </Skeleton>


                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                    Most traded market by volume
                    </Heading>
                    <Skeleton isLoaded={isLoaded}>
                        <Stack direction={'column'}>
                            <Text pt='2' fontSize='sm' fontWeight={'medium'}>
                            {userMostVolumeMarkets.title}
                            </Text>
                            <Text pt='2' fontSize='sm' fontWeight={'bold'}>
                            Volume Done : $ {getBalanceNumber(userMostVolumeMarkets.userData?.balances?.userMarketVolume, 6).toFixed(2)}
                            </Text>
                        </Stack>
                    </Skeleton>

                </Box>
                <Box>
                    <Heading size='xs' textTransform='uppercase'>
                    Available USDC
                    </Heading>
                    <Skeleton isLoaded={isLoaded}>
                        <Text pt='2' fontSize='sm'>
                        ${getFullDisplayBalance(usdcBalance, 6)}
                        </Text>
                    </Skeleton>
                </Box>
                </Stack>
            </CardBody>
        </Card>
    )
}

const NotConnected = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
                <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={'red.500'}
                rounded={'50px'}
                w={'55px'}
                h={'55px'}
                textAlign="center">
                <CloseIcon boxSize={'20px'} color={'white'} />
                </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
                You are not connected
            </Heading>
            <Text color={'gray.500'}>
                You have to connect your wallet according to see this page.
            </Text>
        </Box>
    )
}