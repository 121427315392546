import { Stack, Text, Skeleton, Heading, TableContainer, Table, TableCaption, Thead, Tr, Th, Tbody, 
            Td, Tfoot, Box, Divider, AbsoluteCenter, useColorModeValue, Tabs, TabList, TabPanels, TabPanel, Tab } from "@chakra-ui/react"
import { useMarketFromContract } from "../../../state/hooks"
import { getBalanceNumber } from "../../../utils/formatNumber"
import BigNumber from "bignumber.js"

export default function Orderbook({ contractAddress, limitPriceSetter, outcomeSetter, sideSetter, outcomeLimitPriceSetter }) {
    const market = useMarketFromContract(contractAddress)
    const isLoaded = market.hasOwnProperty('liveMarketData')
    const tableHeadBackground = useColorModeValue('whiteAlpha.900', 'gray.800')

/* 
    {isLoaded ? RenderTable(market.liveMarketData?.orders?.happens, 'happens', getHappensSpread()) : ''}
    {isLoaded ? RenderTable(market.liveMarketData?.orders?.not, 'not', getNotSpread()) : ''}


    const { buy, sell } = orders;

    const buyOrders = buy;
    const sellOrders = sell.length > 0 ? [...sell].reverse() : []; */



    function handleRowClick(limitPrice, outcome, side) {
        
        if(outcome === 'happens') {
            outcomeSetter(false)
        } else {
            outcomeSetter(true)
        }

        if(side === 'buy') {
            sideSetter(1)
            outcomeLimitPriceSetter(limitPrice / 100)
        } else {
            sideSetter(0)
            limitPriceSetter(limitPrice / 100)
        }
    }

    return (
        <Box width={'100%'} minHeight={'450px'} borderWidth={'1px'} borderColor={'gray.500'} borderRadius={'8px'}>
            <Stack padding={'24px'}>
                <Heading size={'md'}>Order Book</Heading>
            </Stack>
            <Tabs variant='line' colorScheme="black">
                <TabList>
                    <Tab fontWeight={500}>Trade Happens</Tab>
                    <Tab fontWeight={500}>Trade Not</Tab>
                </TabList>
                <Divider />
                <TabPanels>
                    <TabPanel display={'flex'} flexDirection={'column'} padding={0}>
                        {isLoaded ? <RenderTable orders={market.liveMarketData?.orders?.happens} handleRowClick={handleRowClick} outcome={'happens'} /> : <></>}
                    </TabPanel>
                    <TabPanel display={'flex'} flexDirection={'column'} padding={0}>
                        {isLoaded ? <RenderTable orders={market.liveMarketData?.orders?.not} handleRowClick={handleRowClick} outcome={'not'} /> : <></>}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    )
}

function RenderTable({orders, handleRowClick, outcome}) {
    const { buy, sell, total_buy, total_sell } = orders;

    const buyOrders = buy;
    const sellOrders = sell.length > 0 ? [...sell].reverse() : [];


    const priceTextRed = useColorModeValue('red.500','red.400');
    const priceTextGreen = useColorModeValue('green.500', 'green.400');
    
    const rowHoverBackgroundRed = useColorModeValue('rgba(254, 178, 178, 0.5)', 'rgba(254, 178, 178, 0.4)')
    const rowHoverBackgroundGreen = useColorModeValue('rgba(154, 230, 180, 0.5)', 'rgba(154, 230, 180, 0.4)')

    const rowSizeBackgroundRed = useColorModeValue('rgba(245, 101, 101, 0.5)', 'rgb(254, 178, 178, 0.4)');
    const rowSizeBackgroundGreen = useColorModeValue('rgba(72, 187, 120, 0.5)','rgba(198, 246, 213, 0.4)');

    function calculateBarSize(total, current) {

        const val = new BigNumber(current).multipliedBy(100).dividedBy(new BigNumber(total)).toString();

        return val
    }

    return (
        <Box height={'335px'} width={'100%'} overflowY={'auto'} borderBottomRadius={'8px'}
        css={{
              '&::-webkit-scrollbar': {
              width: '4px',
              },
              '&::-webkit-scrollbar-track': {
              width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
              background: 'white',
              borderRadius: '24px',
              },
          }}>
          <Box height={'24px'} width={'100%'} 
              display={'flex'} flexDirection={'row'} padding={2} paddingBottom={7}>
              <Text width={'40%'} marginLeft={'4px'} fontSize={'sm'} color="red.400">Sell Orders</Text>
              <Text width={'20%'} textAlign={'center'} fontSize={'sm'} color="gray.400">Price</Text>
              <Text width={'20%'} textAlign={'center'} fontSize={'sm'} color="gray.400">Shares</Text>
              <Text width={'20%'} textAlign={'center'} fontSize={'sm'} color="gray.400">Total</Text>
          </Box>
          {sellOrders.length > 0 ? sellOrders.map((order) => (
              <Box display={'flex'} flexDirection={'row'} height={'40px'} alignItems={'center'}
                  _hover={{
                      backgroundColor: rowHoverBackgroundRed, cursor: 'pointer'
                  }}
                  onClick={() => handleRowClick(order.price, outcome, 'sell')}> 
                  <Box height={'100%'} width={'40%'}>
                      <Box height={'100%'} width={`${calculateBarSize(total_sell, order.amount)}%`} backgroundColor={rowSizeBackgroundRed}> </Box>
                  </Box>
                  <Text width={'20%'} textAlign={'center'} fontWeight={600} fontSize={'lg'} color={priceTextRed}>{new BigNumber(order.price / 100).toFixed(2)}¢</Text>
                  <Text width={'20%'} textAlign={'center'} fontSize={'md'}>{getBalanceNumber(order.amount, 6).toFixed(2)}</Text>
                  <Text width={'20%'} textAlign={'center'} fontWeight={600} fontSize={'md'}>${new BigNumber(getBalanceNumber(order.amount, 6)).multipliedBy(new BigNumber(order.price / 10000)).toFixed(2)}</Text>
              </Box>
          )) : <Text fontSize={'xs'} color={'gray.500'} pl={5}>
          No orders
          </Text>}


          <Box height={'24px'} width={'100%'} 
              display={'flex'} flexDirection={'row'} padding={2} paddingBottom={7}>
              <Text width={'40%'} marginLeft={'4px'} fontSize={'sm'} color="green.400">Buy Orders</Text>
              <Text width={'20%'} textAlign={'center'} fontSize={'sm'} color="gray.400">Price</Text>
              <Text width={'20%'} textAlign={'center'} fontSize={'sm'} color="gray.400">Shares</Text>
              <Text width={'20%'} textAlign={'center'} fontSize={'sm'} color="gray.400">Total</Text>
          </Box>
          {buyOrders.length > 0 ? buyOrders.map((order) => (
              <Box display={'flex'} flexDirection={'row'} height={'40px'} alignItems={'center'}
                  _hover={{
                      backgroundColor: rowHoverBackgroundGreen, cursor: 'pointer'
                  }}
                  onClick={() => handleRowClick(order.price, outcome, 'buy')}> 
                  <Box height={'100%'} width={'40%'}>
                      <Box height={'100%'} width={`${calculateBarSize(total_buy, order.amount)}%`} backgroundColor={rowSizeBackgroundGreen}> </Box>
                  </Box>
                  <Text width={'20%'} textAlign={'center'} fontWeight={600} fontSize={'lg'} color={priceTextGreen}>{new BigNumber(order.price / 100).toFixed(2)}¢</Text>
                  <Text width={'20%'} textAlign={'center'} fontSize={'md'}>{getBalanceNumber(order.amount, 6).toFixed(2)}</Text>
                  <Text width={'20%'} textAlign={'center'} fontWeight={600} fontSize={'md'}>${new BigNumber(getBalanceNumber(order.amount, 6)).multipliedBy(new BigNumber(order.price / 10000)).toFixed(2)}</Text>
              </Box>
          )) : <Text fontSize={'xs'} color={'gray.500'} pl={5}>
          No orders
          </Text>}
      </Box>
    )
}