import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    useColorModeValue,
    Heading,
    Text,
    Stack,
    Skeleton
  } from '@chakra-ui/react'
import { useMarketFromContract } from '../../../state/hooks'
import { orderTransactions } from '../../../utils/APIHelpers';
import { getFullDisplayBalance } from '../../../utils/formatNumber';
import BigNumber from 'bignumber.js';

function TransactionTableXXX({ contractAddress }) {
    const market = useMarketFromContract(contractAddress);
    return(
        <Stack mt={10} pb={5}>
            <Heading size={'md'} mb={5}>Latest Trades</Heading>
            <Text fontSize={'md'}>
                Transaction history is under development.
            </Text>
        </Stack>

    )
}

export default function TransactionTable({ contractAddress }) {
    const market = useMarketFromContract(contractAddress);
    const isLoaded = market.hasOwnProperty('liveMarketData');
    const isAPILoaded = market.hasOwnProperty('apiMarketData');

    const sellColor = useColorModeValue('red.300','red.500');
    const buyColor = useColorModeValue('green.300','green.500');

    function getAveragePrice(_invest, _amount) {
        const invest = new BigNumber(_invest);
        const amount = new BigNumber(_amount);

        return invest.dividedBy(amount).multipliedBy(100).toFixed(0); // cents
    }

    // const lastTransactions = isLoaded ? orderTransactions(market.apiMarketData.info.data.lastTransactions) : [];
    const lastTransactions = isAPILoaded ? market.apiMarketData?.info?.lastTrades : [];
    return (
        <TableContainer pb={5} >
        <Skeleton isLoaded={isLoaded}>
            <Table variant='simple' size="sm">
                <Thead>
                <Tr>
                    <Th>Block</Th>
                    <Th>Side</Th>
                    <Th>Commodity</Th>
                    <Th isNumeric>Avg. Price</Th>
                    <Th isNumeric>Amount</Th>
                    <Th isNumeric>Total</Th>
                </Tr>
                </Thead>
                <Tbody>
                {lastTransactions && lastTransactions.map((tx) => (
                    <Tr backgroundColor={tx.taker_side === "0" ? buyColor : sellColor }>
                        <Td>#{tx.block_number}</Td>
                        <Td>{tx.taker_side === "0" ? 'Buy' : 'Sell'}</Td>
                        <Td>{tx.asset === '0' ? 'Happens' : 'Not'}</Td>
                        <Td isNumeric>{tx.price / 100} ¢</Td>
                        <Td isNumeric>{getFullDisplayBalance(tx.matched_amount, 6, 2)}</Td>
                        <Td isNumeric>${parseFloat(Number(getFullDisplayBalance(tx.matched_amount, 6, 2)) * Number(tx.price / 10000)).toFixed(2)}</Td>
                    </Tr>
                ))}

                </Tbody>
                <Tfoot>

                </Tfoot>
            </Table>
            </Skeleton>
        </TableContainer>
    )
}