import Contracts from '../config/contracts';

export const getForecastAddress = (network) => {
    return Contracts.forecasts[network];
}

export const getUsdcAddress = (network) => {
    return Contracts.usdc[network];
}

export const getEthereumAddress = (network) => {
    return Contracts.ethereum[network];
}

export const getMulticallAddress = (network) => {
    return Contracts.multicall[network];
}

export const getStatisticsAddress = (network) => {
    return Contracts.statistics[network];
}

export const getDistributorAddress = (network) => {
    return Contracts.distributor[network];
}

export const getSharesAddress = (network) => {
    return Contracts.shares[network];
}