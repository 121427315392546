import { useState, useContext } from "react";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { Card, CardHeader, Tabs, Tab, TabList, Popover, PopoverTrigger, TabPanels, 
        TabPanel, Stack, Button, useColorModeValue, Text, Switch, Divider,
        InputGroup, InputLeftElement, InputRightElement, Input, Link, CardFooter,
        useDisclosure, Tooltip, useToast, Skeleton} from "@chakra-ui/react"
import { SettingsIcon, InfoOutlineIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { useStarknetReact } from "@web3-starknet-react/core"
import { getFullDisplayBalance, getBalanceNumber } from '../../../utils/formatNumber';
import { useMarketFromContract, useBalances } from '../../../state/hooks';
import ConnectWallet from '../../../components/Wallet/ConnectWallet';
import MintMergeModal from './MintMergeModal';
import { approveAndInsertBuyOrder, approveAndInsertSellOrder } from '../../../utils/callHelpers';
import BigNumber from "bignumber.js";
import { useAccount, useContractWrite } from "@starknet-react/core";

export default function TradeBox({ contractAddress, limitPrice, setLimitPrice, outcome, setOutcome, tabIndex, setTabIndex, limitPriceOutcome, setLimitPriceOutcome }) {

    const { network } = useContext(NetworkContext);
    const toast = useToast();

    const { address } = useAccount();
    const { writeAsync } = useContractWrite({})

    
    
    const [invest, setInvest] = useState('');
    const [onTxn, setOnTxn] = useState(false);
    const [outcomeInput, setOutcomeInput] = useState('');
    const [limitOrder, setLimitOrder] = useState(true);
    const [limitOrderOutcome, setLimitOrderOutcome] = useState(true);
    // const [limitPriceOutcome, setLimitPriceOutcome] = useState('');
    
    const market = useMarketFromContract(contractAddress);

    const mintMergeModal = useDisclosure();

    const isLoadedUser = market.hasOwnProperty('userData');
    const isLoaded = market.hasOwnProperty('liveMarketData');
    

    const userOutcomeBalances = isLoadedUser ? market.userData.balances : { happens: '0', not: '0'};


    const isConnected = address; 

    const balances = useBalances();

    const usdcBalance = balances.data ? balances.data.usdc : '0'

    const handleChangeLimitPriceOutcome = (event) => setLimitPriceOutcome(event.target.value.replace(',','.'))
    const handleChangeLimitPrice = (event) => setLimitPrice(event.target.value.replace(',','.'))
    const handleChangeInvest = (event) => setInvest(event.target.value.replace(',','.'))
    const handleOutcomeInputChange = (event) => setOutcomeInput(event.target.value.replace(',','.'))
    
    const inactiveButtonColors = useColorModeValue('gray.100', 'gray.600');
    const happensButtonColors = useColorModeValue('green.300', 'green.500');
    const notButtonColors = useColorModeValue('red.300', 'red.500');

    function changeOutcome(value) {
        setOutcome(value);
        setOutcomeInput('')
    }

    function handleLimitOrder() {
        // setLimitOrder(!limitOrder)
    }

    function handleLimitOrderOutcome() {
        // setLimitOrderOutcome(!limitOrderOutcome)
    }

    function routeGetUsdc() {
        const getUSDCURL = 'https://app.fibrous.finance/'
        window.open(getUSDCURL)
    }

    function setMaxInvest() {
        setInvest(getBalanceNumber(usdcBalance,6));
    }

    function setMaxOutcome() {
        setOutcomeInput(getBalanceNumber(userOutcomeBalances[outcome ? 'not' : 'happens'], 6));
    }

    async function submitBuyTransaction() {
        // side 0 = happens, 1 = not
        try {
            setOnTxn(true);
            const currentAmount = invest;
            const orderPrice = limitOrder ? new BigNumber(limitPrice).multipliedBy(100).toString() : '10000'
            const amountAdjusted = new BigNumber(currentAmount).multipliedBy(new BigNumber(10).pow(6)).toString()
            
            await approveAndInsertBuyOrder(writeAsync, network, market, outcome ? '1' : '0', amountAdjusted, orderPrice)
            toast({
                title: 'Transaction Broadcasted',
                description: `Transaction successfully broadcasted.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (ex) {
            toast({
                title: 'Error during sending transaction',
                description: ex.message? ex.message : 'Unknown error',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setOnTxn(false)
        }

    }

    async function submitSellTransaction() {
        try {
            setOnTxn(true);
            const currentAmount = outcomeInput;
            const orderPrice = limitOrderOutcome ? new BigNumber(limitPriceOutcome).multipliedBy(100).toString() : '1'
            const amountAdjusted = new BigNumber(currentAmount).multipliedBy(new BigNumber(10).pow(6)).toString()
            
            await approveAndInsertSellOrder(writeAsync, network, market, outcome ? '1' : '0', amountAdjusted, orderPrice)
            toast({
                title: 'Transaction Broadcasted',
                description: `Transaction successfully broadcasted.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (ex) {
            toast({
                title: 'Error during sending transaction',
                description: ex.message? ex.message : 'Unknown error',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        } finally {
            setOnTxn(false)
        }
    }

    const getBestBuyPrice = () => {
        if(isLoaded) {
            if(outcome) {
                // not
                return market.liveMarketData.orders.not[0]?.price;
            } else {
                return market.liveMarketData.orders.happens[0]?.price;
            }
        } 
        return "0";
    }

    const BuyEstimations = () => {
        const textColor = useColorModeValue('green.500', 'green.200')
        return (
            <>
                <Stack w={'100%'} direction={'row'} justifyContent={'space-between'}>
                    <Text fontWeight={500}>Last price</Text>
                    <Text fontWeight={500}>{getBestBuyPrice()} ¢</Text>
                </Stack>
                <Stack w={'100%'} direction={'row'} justifyContent={'space-between'}>
                <Text fontWeight={500}>Min. Amount</Text>
                    {invest > 0 ? <Text color={textColor} fontWeight={500}> {limitOrder ? (invest / ((limitPrice > 0 ? limitPrice : 1) / 100)).toFixed(2) : (invest.toFixed(2))}</Text> : <></>}
                </Stack>
            </>
        )
    }

    const SellEstimations = () => {
        const textColor = useColorModeValue('green.500', 'green.200')
        return (
            <>
                <Stack w={'100%'} direction={'row'} justifyContent={'space-between'}>
                    <Text fontWeight={500}>Last price</Text>
                    <Text fontWeight={500}>-- ¢</Text>
                </Stack>
                <Stack w={'100%'} direction={'row'} justifyContent={'space-between'}>
                <Text fontWeight={500}>Min. Amount</Text>
                    {invest > 0 ? <Text color={textColor} fontWeight={500}> {limitOrder ? (invest / ((limitPrice > 0 ? limitPrice : 1) / 100)) : (invest)}</Text> : <></>}
                </Stack>
            </>
        )
    }


    return(
        <Card maxW='base' boxShadow={'xl'}>
        <MintMergeModal isOpen={mintMergeModal.isOpen} onClose ={mintMergeModal.onClose } market={market} />
            <CardHeader>
            <Tabs index={tabIndex} isFitted variant='unstyled' onChange={(index) => setTabIndex(index)}>
                    <TabList mb='1em'>
                        <Tab borderRadius={'md'} letterSpacing={1} fontWeight= '500' _selected={{ color : useColorModeValue('white', 'black'), bg: useColorModeValue('blue.500', 'blue.200')}}>Buy</Tab>
                        <Tab borderRadius={'md'} letterSpacing={1} fontWeight= '500' _selected={{ color : useColorModeValue('white', 'black'), bg: useColorModeValue('blue.500', 'blue.200')}}>Sell</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                        <Stack>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Text fontWeight={500}>
                                        Outcome
                                    </Text>
                            </Stack>
                            <Stack direction={'row'}>
                                <Button _hover={{backgroundColor : outcome == false ? happensButtonColors : inactiveButtonColors}} onClick={() => changeOutcome(false)} backgroundColor={ outcome == false ? happensButtonColors : inactiveButtonColors} flex={1}>Happens</Button>
                                <Button _hover={{backgroundColor : outcome == true ? notButtonColors : inactiveButtonColors}} onClick={() => changeOutcome(true)} backgroundColor={ outcome == true ? notButtonColors : inactiveButtonColors} flex={1}>Not</Button>
                            </Stack>

                            {limitOrder ? <>
                                <Text fontWeight={500}>
                                                Limit Price
                                            </Text>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                                            <InputGroup>
                                                <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1em'
                                                children='¢'
                                                />
                                                <Input 
                                                isInvalid={limitPrice > 100 || limitPrice === '0' || limitPrice < 0}
                                                type='number'
                                                placeholder='0' 
                                                value={limitPrice} 
                                                onChange={handleChangeLimitPrice} 
                                                /> {/* Burda value handleda bi problem var error veriyo */}
                                                <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setLimitPrice('100')}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                            </InputGroup>
                                    </Stack> 
                                    </>: <></>}

                            {/*
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <Text fontWeight={500}>
                                            Limit Order
                                        </Text>
                                        <Tooltip label='If you dont specify limit price, limit price will automatically set to 1$. This means if your order wont filled instantly, then there will be buy order added with 0.9999 $ price.' fontSize='sm'>
                                            <InfoOutlineIcon fontSize={'sm'} />
                                        </Tooltip>
                                    </Stack>
                                    <Switch colorScheme='teal' size='lg' onChange={() => handleLimitOrder()}/>
                            </Stack> */}
                                <Text fontWeight={500}>
                                        Amount to Spend (USDC)
                                </Text>
                                <Stack>

                                    <InputGroup>
                                        <InputLeftElement
                                        pointerEvents='none'
                                        color='gray.300'
                                        fontSize='1em'
                                        children='$'
                                        />
                                        <Input 
                                        isInvalid={invest > getBalanceNumber(usdcBalance, 6) || invest < 0}
                                        type='number'
                                        placeholder='0' 
                                        value={invest} 
                                        onChange={handleChangeInvest} 
                                        /> {/* Burda value handleda bi problem var error veriyo */}
                                        <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setMaxInvest()}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                    </InputGroup>
                                    {isConnected ? (
                                        <Stack direction={'row'} justifyContent={'space-between'} >
                                            <Text fontWeight={400} mb={2} fontSize={'xs'}>
                                                Available : {getFullDisplayBalance(usdcBalance,6,2)} USDC
                                            </Text>
                                            <Stack cursor={'pointer'} direction={'row'} onClick={() => routeGetUsdc()}>
                                            <Link colorScheme='blue' fontWeight={'500'} fontSize={'xs'}>Get USDC</Link>
                                            <ExternalLinkIcon/>
                                            </Stack>
                                        
                                        </Stack>
                                    ) : (<></>)}
                                </Stack> 

                                {isConnected ? (<Button isLoading={onTxn}
                                                    loadingText='Broadcasting Transaction' 
                                                    isDisabled={ onTxn || invest > getBalanceNumber(usdcBalance, 6) || invest == 0 || invest == '' || invest < 0 || (limitOrder && limitPrice > 100)} 
                                                    colorScheme='blue' 
                                                    onClick={() => submitBuyTransaction()}>Approve & Transact</Button>) : (<ConnectWallet />)}
                        </Stack>

                        </TabPanel>
                        <TabPanel>
                            <Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Text fontWeight={500}>
                                            Outcome
                                        </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Button _hover={{backgroundColor : outcome == false ? happensButtonColors : inactiveButtonColors}} onClick={() => changeOutcome(false)} backgroundColor={ outcome == false ? happensButtonColors : inactiveButtonColors} flex={1}>Happens</Button>
                                    <Button _hover={{backgroundColor : outcome == true ? notButtonColors : inactiveButtonColors}} onClick={() => changeOutcome(true)} backgroundColor={ outcome == true ? notButtonColors : inactiveButtonColors} flex={1}>Not</Button>
                                </Stack>

                                {limitOrderOutcome ? <>
                                <Text fontWeight={500}>
                                                Limit Price
                                            </Text>
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>

                                            <InputGroup>
                                                <InputLeftElement
                                                pointerEvents='none'
                                                color='gray.300'
                                                fontSize='1em'
                                                children='¢'
                                                />
                                                <Input 
                                                isInvalid={limitPriceOutcome > 100 || limitPriceOutcome === '0' || limitPriceOutcome < 0}
                                                type='number'
                                                placeholder='0' 
                                                value={limitPriceOutcome} 
                                                onChange={handleChangeLimitPriceOutcome} 
                                                /> {/* Burda value handleda bi problem var error veriyo */}
                                                <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setLimitPriceOutcome('100')}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                            </InputGroup>
                                    </Stack> 
                                    </>: <></>}

                                    {/*
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <Text fontWeight={500}>
                                                Limit Order
                                            </Text>
                                            <Tooltip label='If you dont specify limit price, limit price will automatically set to 1$. This means if your order wont filled instantly, then there will be buy order added with 0.9999 $ price.' fontSize='sm'>
                                                <InfoOutlineIcon fontSize={'sm'} />
                                            </Tooltip>
                                        </Stack>
                                        <Switch colorScheme='teal' size='lg' onChange={() => handleLimitOrderOutcome()}/>
                                    </Stack> */}
                                        <Text fontWeight={500}>
                                                Amount to Sell ({outcome ? 'Not' : 'Happens'})
                                        </Text>
                                        <Stack>

                                            <InputGroup>
                                                <Input 
                                                isInvalid={outcomeInput > getBalanceNumber(userOutcomeBalances[outcome ? 'not' : 'happens'], 6) || outcomeInput < 0}
                                                type='number'
                                                placeholder='0' 
                                                value={outcomeInput} 
                                                onChange={handleOutcomeInputChange} 
                                                /> {/* Burda value handleda bi problem var error veriyo */}
                                                <InputRightElement children={<Button height={'90%'} colorScheme='gray' pl={6} pr={6} mr={2} onClick={() => setMaxOutcome()}><Text fontSize={'xs'}>MAX</Text></Button>} />
                                            </InputGroup>
                                            {isConnected ? (
                                                <Stack direction={'row'} justifyContent={'space-between'} >
                                                        <Stack direction={'row'}>
                                                            <Text fontWeight={400} mb={2} fontSize={'xs'}>Available : {getFullDisplayBalance(userOutcomeBalances[outcome ? 'not' : 'happens'],6,2)}</Text> 
                                                            <Text fontSize={'xs'} fontWeight={'bold'}>{outcome ? 'NOT' : 'HAPPENS'}</Text>
                                                        </Stack>
                                                </Stack>
                                            ) : (<></>)}
                                        </Stack> 

                                        {isConnected ? (<Button isLoading={onTxn}
                                                    loadingText='Broadcasting Transaction' 
                                                    isDisabled={ onTxn || outcomeInput > getBalanceNumber(userOutcomeBalances[outcome ? 'not' : 'happens'], 6) || outcomeInput == 0 || outcomeInput == '' || outcomeInput < 0 || (limitOrderOutcome && limitPriceOutcome > 100)} 
                                                    colorScheme='blue' 
                                                    onClick={() => submitSellTransaction()}>Approve & Transact</Button>) : (<ConnectWallet />)}
                            </Stack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </CardHeader>
            <CardFooter
                justify='space-between'
                flexWrap='wrap'
                sx={{
                '& > button': {
                    minW: '136px',
                },
                }}
            >
                {
                    tabIndex == 0 ? ( <BuyEstimations /> ) : ( <SellEstimations /> )
                }
                {
                    isConnected ? (
                        <Stack mt={5} w={'100%'}>
                    <Divider />
                    <Button colorScheme='blue' fontSize={'md'} onClick={() => mintMergeModal.onOpen()}>Mint & Merge Shares</Button>
                </Stack>
                    ) : <></>
                }

            </CardFooter>
        </Card>
    )
}