import { createSlice } from "@reduxjs/toolkit";
import markets from "../../config/markets";
import {fetchOrderbookMarkets } from './fetchMarkets';
import fetchMarketsApi from "./fetchMarketsAPI";
import { fetchOrderbookMarketUserBalances, fetchMarketUserBalances } from "./fetchMarketUser";

const initialState = { data: [...markets] };

export const marketsSlice = createSlice({
    name: 'Markets',
    initialState,
    reducers: {
        setMarketsPublicData: (state, action) => {
            const liveMarketsData = action.payload;
            state.data = state.data.map((market) => {
                const liveMarketData = liveMarketsData.find((f) => f.contractAddress === market.contractAddress);
                return { ...market, liveMarketData };
            })
        },
        setMarketsUserData: (state, action) => {
            const { arrayOfUserDataObject } = action.payload
            arrayOfUserDataObject.forEach((elem) => {
                const { index } = elem;
                state.data[index] = { ...state.data[index], userData: elem }
            })
        },
        setMarketsApiData: (state, action) => {
            const apiMarketsData = action.payload;
            state.data = state.data.map((market) => {
                const apiMarketData = apiMarketsData.find((f) => f.contractAddress === market.contractAddress);
                return { ...market, apiMarketData };
            })
        }
    }
})

export const { setMarketsPublicData, setMarketsUserData, setMarketsApiData } = marketsSlice.actions;

export const fetchMarketsPublicDataAsync = () => async (dispatch) => {
    const orderbookMarkets = await fetchOrderbookMarkets();
    dispatch(setMarketsPublicData([...orderbookMarkets]));
}

export const fetchMarketsApiDataAsync = () => async (dispatch) => {
    const markets = await fetchMarketsApi();
    dispatch(setMarketsApiData(markets));
}

export const fetchMarketsUserDataAsync = (account) => async(dispatch) => {

    const userMarketBalances = await fetchMarketUserBalances(account);

    const arrayOfUserDataObject = userMarketBalances.map((balances, index) => {
        return {
            index,
            balances: userMarketBalances[index]
        }
    })
    /*
    const userMarketUsdcAllowances = await fetchMarketUserUsdcAllowances(account);
    const userMarketHappensBalances = await fetchMarketUserHappensBalance(account);
    const userMarketNotBalances = await fetchMarketUserNotBalance(account);

    const arrayOfUserDataObject = userMarketUsdcAllowances.map((marketAllowance, index) => {
        return {
            index,
            allowance: userMarketUsdcAllowances[index],
            happensBalance: userMarketHappensBalances[index],
            notBalance: userMarketNotBalances[index]
        }
    }) */

    dispatch(setMarketsUserData({ arrayOfUserDataObject }));
}

export default marketsSlice.reducer;