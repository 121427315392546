import { Box } from '@chakra-ui/react'

const Page = ({children}) => { // direk text gönderme
    return (
        <Box>        
            {children}
        </Box>
    )
};

export default Page;