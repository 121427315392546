import Page from "../../components/layout/Page"
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  Link,
  Grid, GridItem, Skeleton
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useMarkets } from "../../state/hooks";
import TrendingMarket from "./components/TrendingMarket";
import Markets from "./components/Markets";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAccount } from "@starknet-react/core";
import useRefresh from "../../hooks/useRefresh";
import { fetchMarketsUserDataAsync } from "../../state/markets";
import { fetchUserBalancesAsync } from "../../state/user";
import { getHomeInfo } from "../../utils/APIHelpers";
import RecentActivities from "./components/RecentActivities";
import TopVolumers from "./components/TopVolumers";


const Home = ({resolved}) => {
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const { address } = useAccount();
  const markets = useMarkets();
  const homeMarkets = markets.filter((m) => m.listAtHome);

  useEffect(() => {
    if(address) {
        dispatch(fetchMarketsUserDataAsync(address))
        dispatch(fetchUserBalancesAsync(address, 'mainnet'))
    }
   
}, [fastRefresh, dispatch, address])

    const trendingMarketBackgroundColors = useColorModeValue([
      {start: 'green.300', end: 'green.200'}, 
      {start: 'red.300', end: 'red.200'},
      {start: 'purple.300', end: 'purple.200'},
      {start: 'orange.300', end: 'orange.200'}], 
      [      
      {start: 'green.500', end: 'green.400'}, 
      {start: 'red.500', end: 'red.400'},
      {start: 'purple.500', end: 'purple.400'},
      {start: 'orange.500', end: 'orange.400'}])
    return (
      <Page>
        <Container marginTop={8} maxW={'8xl'}>
          {!resolved ? (          <Stack minHeight={'180px'} width={'100%'}
                display={'flex'} flexDirection={{base: 'column', xl:'row'}} alignItems={'center'} justifyContent={'space-evenly'}>
                {homeMarkets.map((market, index) => (
                  <TrendingMarket bgColor={trendingMarketBackgroundColors[index]} market={market}/>
                ))}
          </Stack>) : (<></>)}
          </Container>
          <Markets resolved={resolved} />
        {!resolved ? (<Activities />) : (<></>)}
      </Page>

    )
  }

function Activities() {
  const [apiLoaded, setApiLoaded] = useState(false);
  const [lastActivities, setLastActivities] = useState([]);
  const [topVolumes, setTopVolumes] = useState([]);
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    async function loadHomeInfo() {
      const info = await getHomeInfo()

      setApiLoaded(true)
      setLastActivities(info.latestActivities)
      setTopVolumes(info.mostVolumers)
    }
    loadHomeInfo()
  }, [slowRefresh])

  return (
    <Container marginTop={8} maxW={'8xl'}>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Box minHeight={'220px'} width={'45%'}>
            <RecentActivities isLoaded={apiLoaded} activities={lastActivities} />
        </Box>
        <Box minHeight={'220px'} width={'45%'} pb={5}>
            <TopVolumers isLoaded={apiLoaded} volumers={topVolumes} />
        </Box>
      </Stack>
    </Container>
  )
}
  
export default Home