import { getUsdcBalance } from '../../utils/callHelpers';

const fetchBalances = async (account, network) => {
    try {
        const usdcBalance = await getUsdcBalance(account, network);
        return {
            usdc : usdcBalance.toString() // bignumberada çevrilebilir burda gelen bn
        };
    } catch (ex) {
        console.error(ex)
        return;
    }
}


export default fetchBalances;