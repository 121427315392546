import { RepeatIcon, EditIcon, LinkIcon, ChatIcon } from "@chakra-ui/icons";
import { Stack, Text, Image, Heading, Box, useColorModeValue, Link, Button, Skeleton } from "@chakra-ui/react";
import { useMarketFromContract } from "../../../state/hooks";
import { BLOCK_EXPLORER } from "../../../config/contants";
import { getPrices } from "../../../utils/calculateOdds";

export default function MarketInfo({ contractAddress }) {
    const market = useMarketFromContract(contractAddress);

    const isLoaded = market.hasOwnProperty('liveMarketData')
    const prices = isLoaded ? getPrices(market.liveMarketData.happens, market.liveMarketData.not) : ''

    // console.log(market)
    function routeExternal(url) {
        window.open(url)
    }

    return (
        <Stack mt={'5'} >

            <Stack pt={5}>
                
                <Heading size={'md'}>Description</Heading>
                <Text fontSize={'sm'}>
                {market.description}
                    
                </Text>
                <Stack direction={'row'} spacing={5} pt={4}>
                    <Stack direction={'row'} alignItems={'center'} px={{ base: 2, md: 4 }}
                            py={{ base: 2, md: 5 }}
                            shadow={'md'}
                            border={['1px solid','2px solid']}
                            borderColor={useColorModeValue('gray.800', 'gray.500')}
                            rounded={'lg'}
                            cursor={'pointer'}
                            _hover={{borderColor : useColorModeValue('green.400', 'green.300')}}
                            onClick={() => routeExternal(market.resolutionSource)}>
                            <LinkIcon />
                            <Text fontSize={'xs'}>Resolution Source</Text>

                    </Stack>
                    <Stack display={['none', 'flex']} direction={'row'} alignItems={'center'} px={{ base: 2, md: 4 }}
                            py={{ base: 2, md: 5 }}
                            shadow={'md'}
                            border={['1px solid','2px solid']}
                            borderColor={useColorModeValue('gray.800', 'gray.500')}
                            rounded={'lg'}
                            cursor={'pointer'}
                            _hover={{borderColor : useColorModeValue('green.400', 'green.300')}}
                            onClick={() => routeExternal(`${BLOCK_EXPLORER}contract/${market.contractAddress}`)}>
                            <EditIcon />
                            <Text fontSize={'xs'}>Smart Contract</Text>

                    </Stack>
                    <Stack direction={'row'} alignItems={'center'} px={{ base: 6, md: 8 }}
                            py={{ base: 2, md: 5 }}
                            shadow={'md'}
                            border={['1px solid','2px solid']}
                            borderColor={useColorModeValue('gray.800', 'gray.500')}
                            rounded={'lg'}
                            justifyContent={'space-between'}>
                            <RepeatIcon />
                            <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'}>
                                <Text textAlign={'center'} fontWeight={600} fontSize={['xs']}>Maker: {market.fees.maker}%</Text>
                                <Text textAlign={'center'} fontWeight={600} fontSize={'xs'}>Taker: {market.fees.taker}%</Text>
                            </Stack>

                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

function PriceBar({happens, not, isLoaded}) {
    const happensBackgroundColor = useColorModeValue('green.300', 'green.500');
    const notBackgroundColor = useColorModeValue('red.300', 'red.500');
    return (
        <Skeleton isLoaded={isLoaded}>
            <Stack direction={'row'} width={'100%'} spacing={0} height={50}>
                <Stack width={`${happens}%`} 
                        backgroundColor={happensBackgroundColor} 
                        borderRadius={'sm'}
                        textAlign={'center'} justifyContent={'center'} alignItems={'center'}
                        mr={1}>
                    <Text fontWeight={500}>Happens {happens} ¢</Text>
                </Stack>
                <Stack ml={1} borderRadius={'sm'}
                        width={`${not}%`} backgroundColor={notBackgroundColor}
                        textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                    <Text fontWeight={500}>Not {not} ¢</Text>
                </Stack>
            </Stack>
        </Skeleton>

    )
}

function RenderPriceBarOrResolveRatio(liveMarketData, isLoaded, prices) {
    const happensBackgroundColor = useColorModeValue('green.300', 'green.500');
    const notBackgroundColor = useColorModeValue('red.300', 'red.500');
    if(liveMarketData.isResolved) {
        return (
            <Stack direction={'row'} width={'100%'} spacing={0} height={50}>
            {Number(liveMarketData.resolve_0) == 10000 ? 
            (
                <Stack width={`100%`} 
                        backgroundColor={happensBackgroundColor} 
                        borderRadius={'sm'}
                        textAlign={'center'} justifyContent={'center'} alignItems={'center'}
                        mr={1}>
                    <Text fontWeight={500}>Resolved as Happens</Text>
                </Stack>
            ) : (
                <Stack width={`100%`} 
                        backgroundColor={notBackgroundColor} 
                        borderRadius={'sm'}
                        textAlign={'center'} justifyContent={'center'} alignItems={'center'}
                        mr={1}>
                    <Text fontWeight={500}>Resolved as Not</Text>
                </Stack>
            )}

            </Stack>
        )
    } else {
        return (
            <PriceBar isLoaded={isLoaded} happens={isLoaded ? prices.happens.toFixed(0) : '0'} not= {isLoaded ? prices.not.toFixed(0) : '0'} />
        )

    }
}