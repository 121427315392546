import Page from "../../components/layout/Page";
import { useEffect, useContext, useState } from "react";
import { Container, Stack, Grid, GridItem, Divider, Text, Heading, Image, Tabs, TabList, TabPanels, Tab, TabPanel} from "@chakra-ui/react";
import TransactionTable from "./components/TransactionTable";
import Statistics from "./components/Statistics";
import MarketInfo from "./components/MarketInfo";
import TradeBox from "./components/TradeBox";
import ConvertBox from "./components/ConvertBox";
import { NetworkContext } from '../../contexts/NetworkContext';
import { useStarknetReact } from "@web3-starknet-react/core";
import { useParams } from "react-router-dom";
import useRefresh from "../../hooks/useRefresh";
import { useDispatch } from "react-redux";
import { fetchMarketsUserDataAsync } from "../../state/markets";
import { fetchUserBalancesAsync } from "../../state/user";
import { useMarketFromContract, useBalances } from "../../state/hooks";
import { unpackOrder } from "../../utils/orderHelpers";
import Orderbook from "./components/Orderbook";
import UserOrders from './components/UserOrders';
import Comments from "./components/Comments";
import { useAccount } from "@starknet-react/core";

export default function Market() {
    // const { account } = useStarknetReact();
    const { address } = useAccount();
    const { marketId } = useParams();
    const { fastRefresh } = useRefresh();
    const dispatch = useDispatch();
    const market = useMarketFromContract(marketId)
    const { network } = useContext(NetworkContext);
    const isLoaded = market.hasOwnProperty('liveMarketData');
    const isResolved = isLoaded ? market.liveMarketData.isResolved : false

    const [limitPrice, setLimitPrice] = useState('');
    const [limitPriceOutcome, setLimitPriceOutcome] = useState('');
    const [outcome, setOutcome] = useState(false);
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        if(address) {
            dispatch(fetchMarketsUserDataAsync(address))
            dispatch(fetchUserBalancesAsync(address, network))
        }
    }, [fastRefresh, dispatch, address, network])

        return (
            <Page>
            <Container maxW={'6xl'}>
                <Statistics contractAddress={marketId}/>
                <Grid minH='400px' templateColumns={['repeat(1, 1fr)','repeat(7, 1fr)']} gap={5} mt={5} mb={10}>
                    <GridItem colSpan={5}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pb={5}>
                            <Heading size={'lg'}>{market.title}</Heading>
                                    
                        <Image borderRadius={'md'} boxSize={'125px'} src={market.image} alt="Event Avatar" />

                    </Stack>
                    
                        <Orderbook contractAddress={marketId} limitPriceSetter={setLimitPrice} outcomeSetter={setOutcome} sideSetter={setTabIndex} outcomeLimitPriceSetter={setLimitPriceOutcome} />
                        <MarketInfo contractAddress={marketId}/>
                        {address ? <UserOrders contractAddress={marketId} /> : <></>}
                        <Tabs variant='enclosed'  minHeight={'400px'} colorScheme="black" mt={5}>
                            <TabList>
                                <Tab>Recent Trades</Tab>
                                <Tab>Comments</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                <TransactionTable contractAddress={marketId}/>
                                </TabPanel>
                                <TabPanel>
                                <Comments market={market.contractAddress}/>
                                </TabPanel>
                            </TabPanels>
                        </Tabs> 
                        
                    </GridItem>
                    <GridItem colSpan={2}>
                        <Stack>
                            {
                                isResolved ? 
                                (<ConvertBox contractAddress={marketId} />) 
                                : 
                                (<TradeBox contractAddress={marketId} 
                                    limitPrice={limitPrice} setLimitPrice={setLimitPrice}
                                    outcome={outcome} setOutcome={setOutcome}
                                    tabIndex={tabIndex} setTabIndex={setTabIndex}
                                    limitPriceOutcome={limitPriceOutcome} setLimitPriceOutcome={setLimitPriceOutcome}
                                />)
                            }
                        </Stack>
                    </GridItem>

                </Grid>  
            </Container>
        </Page>   
        )

}