import BigNumber from "bignumber.js";
import { getBalanceNumber } from "./formatNumber";
const ACHIEVEMENTS = [
    {
        text: "Make $ 200.000 volume",
        desc: "Active orders do not count.",
        status_calculator : (markets) => calculateStatusTotalVolume(markets),
    },
    {
        text: "Make 50 trades",
        desc: "Only matched orders and market orders count.",
        status_calculator : (markets) => calculateStatusTotalTradeCount(markets),
    }, {
        text: "Make 10 trades in Crypto related markets",
        desc: "Only crypto related markets count",
        status_calculator : (markets) => calculateTradesInCryptoMarkets(markets),
    }, {
        text: "Make $ 50000 volume on Fibrous market",
        desc: "Active orders do not count.",
        status_calculator : (markets) => calculateFibrousMarketVolume(markets),
    }
]

export const getAchievements = () => {
    return ACHIEVEMENTS
}

const calculateFibrousMarketVolume = (markets) => {
    const FIBROUS_MARKET = '0x07ee442022ad40398c1fca978c7e3c47abd52aec0daa53b6f25b7ecb8f323be1';
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData');
    if(!isLoaded) {
        return 0;
    }

    const fibrousMarket = markets.find((m) => m.contractAddress === FIBROUS_MARKET);

    const userVolume = new BigNumber(getBalanceNumber(fibrousMarket.userData?.balances?.userMarketVolume, 6));
    const volumeNeeded = new BigNumber(50000);

    if(userVolume.isGreaterThanOrEqualTo(volumeNeeded)) {
        return 1;
    }

    const ratio = userVolume.dividedBy(volumeNeeded);

    return ratio;
}

const calculateTradesInCryptoMarkets = (markets) => {
    const CRYPTO_MARKETS = [
        '0x074943cca49ade8d96defd746c9a72722f3a99cfc47d67ba9c911c4bcafe7e4a',
        '0x053a08bdd318dca1d6acbebe3897c66ea10ea0344b832b8a5e73e57c8d2f590d',
        '0x043c9919fd2af8a58ac6d0779f8c250206c96b2c75d8f5ea2c96f31f786b2b61',
        '0x07ee442022ad40398c1fca978c7e3c47abd52aec0daa53b6f25b7ecb8f323be1'
    ];

    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData');
    if(!isLoaded) {
        return 0;
    }

    const cryptoMarkets = markets.filter((x) => CRYPTO_MARKETS.indexOf(x.contractAddress) > -1);

    const totalTradesInMarkets = cryptoMarkets.reduce((acc, cur) => acc + Number(cur.userData?.balances?.userMarketTrades), 0);

    const totalTradesNeeded = 10;    

    if(totalTradesInMarkets >= totalTradesNeeded) {
        return 1;
    }

    const ratio = totalTradesInMarkets / totalTradesNeeded;

    return ratio;
}

const calculateStatusTotalTradeCount = (markets) => {
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData');
    const userTotalTrades = isLoaded ? Number(markets[0].userData?.balances?.userTotalTrades) : 0;
    const totalTradesNeeded = 50;

    if(userTotalTrades >= totalTradesNeeded) {
        return 1;
    }

    const ratio = userTotalTrades / totalTradesNeeded;

    return ratio
}

const calculateStatusTotalVolume = (markets) => {
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('userData');
    const userTotalVolume = isLoaded ? markets[0].userData?.balances?.userTotalVolume : '0';
    const userTotalVolumeAdjusted = new BigNumber(getBalanceNumber(userTotalVolume, 6));
    const totalVolumeNeeded = new BigNumber(200000);

    if(userTotalVolumeAdjusted.isGreaterThanOrEqualTo(totalVolumeNeeded)) {
        return 1;
    }

    const amountLeft = totalVolumeNeeded.minus(userTotalVolumeAdjusted);

    const ratio = userTotalVolumeAdjusted.dividedBy(totalVolumeNeeded);

    return ratio.toNumber()
}