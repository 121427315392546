import { RpcProvider, constants } from "starknet";
import { publicProvider } from "@starknet-react/core";

const mainnetRpcs = ['https://starknet-mainnet.public.blastapi.io', 'https://starknet-mainnet.public.blastapi.io/rpc/v0_6']
const testnetRpcs = [];

export const getProvider = (network) => {
    if(network === 'testnet') {
        return new RpcProvider({ nodeUrl: mainnetRpcs[Math.floor(Math.random() * mainnetRpcs.length)] });
    } else if(network === 'mainnet') {
        return new RpcProvider({ nodeUrl: mainnetRpcs[Math.floor(Math.random() * mainnetRpcs.length)]});
    }
    return;
}
