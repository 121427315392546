import { Contract, Provider, CallData, uint256, stark, cairo } from "starknet";
import { getProvider } from "./getProvider";
import ForecastABI from '../config/ABI/Forecast.json';
import UsdcABI from '../config/ABI/USDC.json';
import MarketABI from '../config/ABI/MarketABI.json';
import MulticallABI from '../config/ABI/Multicall.json';
import OrderbookMarketABI from '../config/ABI/MarketOrderbook.json';
import OrderbookABI from '../config/ABI/Orderbook.json';
import SharesABI from '../config/ABI/Shares.json';
import DistributionABI from '../config/ABI/Distributor.json';
import { getForecastAddress, getUsdcAddress, getMulticallAddress, getStatisticsAddress, getSharesAddress, getDistributorAddress, getEthereumAddress } from "./addressHelper";
import BN from "bn.js";
import { unpackOrderWithID } from "./orderHelpers";
import BigNumber from "bignumber.js";
import { SHARES_LATEST_STATE } from "../config/contants";

export const getSharesPublicInfo = async (network) => {
    try {
        const provider = getProvider(network);
        const shares = getSharesAddress(network);
        const usdc = getUsdcAddress(network);
        const distributor = getDistributorAddress(network);
        const multicall = getMulticallAddress(network);
    
        const calldata = CallData.compile({
            shares, distributor, distribution_token: usdc,
            state_id: SHARES_LATEST_STATE
        })
    
        const multicallContract = new Contract(MulticallABI, multicall, provider);
    
        const response = await multicallContract.call('get_shares_info', calldata);
        return response;
    } catch (ex) {
        console.error(ex)
        return;
    }
}


export const getAvailableShares = async (network, stateId) => {
    try {
        const provider = getProvider(network);
        const address = getSharesAddress(network);

        const sharesContract = new Contract(SharesABI, address, provider);

        const calldata = CallData.compile({
            state_id: stateId
        })

        const state = await sharesContract.call('get_state', calldata);
        return state.amount;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getTotalUsdcDistribution = async (network) => {
    try {
        const provider = getProvider(network);
        const address = getDistributorAddress(network);
        const usdc = getUsdcAddress(network);

        const sharesContract = new Contract(DistributionABI, address, provider);

        const calldata = CallData.compile({
            token: usdc
        })

        const amount = await sharesContract.call('total_distribution', calldata);
        return amount;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getUserShares = async (network, user) => {
    try {
        const provider = getProvider(network);
        const address = getMulticallAddress(network);
        const shares = getSharesAddress(network);
        const usdc = getUsdcAddress(network);
        const distributor = getDistributorAddress(network);

        const multicall = new Contract(MulticallABI, address, provider);

        const calldata = CallData.compile({
            shares: shares,
            user: user,
            distributor: distributor,
            distribution_token: usdc
        })

        const result = await multicall.call('get_shares_user_info', calldata);
        return result;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

// DEPRECATED METHODS


export const callContractWithoutParams = async (ABI, address, method, network) => {
    try {
        const provider = getProvider(network)
        const myTestContract = new Contract(ABI, address, provider);
        const response = await myTestContract.call(method,[],{    
            parseRequest: false,
            parseResponse: false
        })// cairo 1 support için options
        return response;
    } catch (ex) {
        console.error(ex)
        return;
    }
}

export const callContract = async (ABI, address, method, params, network) => {
    try {
        const provider = getProvider(network)
        const myTestContract = new Contract(ABI, address, provider);
        const response = await myTestContract.balance_of(params)// array olması gerekiyo diyo hata dönüyo
        return response;
    } catch (ex) {
        console.error(ex)
        return;
    }
}

export const getAMMMarketResolves = async(ABI, address, side, network) => {
    try {
        const provider = getProvider(network);
        const contract = new Contract(ABI, address, provider);

        const calldata = CallData.compile({
            side: side
        })
        const response = await contract.resolve_result(calldata, { parseRequest: false, parseResponse: false});
        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}


export const getForecastBalances = async (tokenId, owner, network) => {
    try {
        const provider = getProvider(network)
        const address = getForecastAddress(network);
        const contract = new Contract(ForecastABI, address, provider);
        const calldata = CallData.compile({
            account: owner,
            token: tokenId,
        })
        const response = await contract.balance_of(calldata, { parseRequest: false, parseResponse: false});
        return response;
    } catch (ex) {
        console.error(ex)
        return;
    }
}

export const getUserLiquidity = async (market, account, network) => {
    try {
        const provider = getProvider(network);
        const contract = new Contract(MarketABI, market, provider);
        const calldata = CallData.compile({
            account: account,
        })

        const response = await contract.balance_of(calldata, { parseRequest: false, parseResponse: false});
        return response;
    } catch (ex) {
        console.error(ex)
        return;
    }
}

export const getUsdcBalance = async (account, network) => {
    try {
        const provider = getProvider(network);
        const address = getUsdcAddress(network);
        const contract = new Contract(UsdcABI, address, provider);

        const calldata = CallData.compile({
            account: account
        });
        const response = await contract.balanceOf(account);
        return uint256.uint256ToBN(response.balance)
    } catch (ex) {
        console.error(ex)
        return;
    }
}


////////////////// NEW METHODS FOR ORDER BOOK

export const getShareBalances = async (account, market, network) => {
    try {
        const provider = getProvider(network)
        const contract = new Contract(OrderbookMarketABI, market, provider);
        const calldata_happens = CallData.compile({
            account: account,
            asset: '0',
        })
        const response_happens = await contract.balance_of(calldata_happens);
        const calldata_not = CallData.compile({
            account: account,
            asset: '1',
        })
        const response_not = await contract.balance_of(calldata_not);
        return {
            account: account,
            happens: response_happens,
            not: response_not
        };
    } catch (ex) {
        console.error(ex)
        return;
    }
}


export const getOrderbookMarketCollaterals = async(address, asset, network) => {
    try {
        const provider = getProvider(network);
        const contract = new Contract(OrderbookMarketABI, address, provider);

        const calldata = CallData.compile({
            asset: asset
        })
        const response = await contract.total_supply(calldata);
        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}


export const getOrderbookResolveRate = async (address, network) => {
    try {
        const provider = getProvider(network);
        const contract = new Contract(OrderbookMarketABI, address, provider);

        const response = await contract.resolve_rate();
        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getMarketCollateralAmount = async (address, network) => {
    try {
        const provider = getProvider(network);
        const contract = new Contract(OrderbookMarketABI, address, provider);

        const calldata = CallData.compile({
            asset : '0'
        });

        const response = await contract.total_supply(calldata);

        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getOrderbookUserOrders = async (address, network, user) => {
    try {
        const provider = getProvider(network);

        const contract = new Contract(OrderbookABI, address, provider);
        const calldata = CallData.compile({
            user : user
        });

        const response = await contract.get_user_orders(calldata);

        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getOrderFromID = async (address, network, orderId) => {
    try {
        const provider = getProvider(network);

        const contract = new Contract(OrderbookABI, address, provider);
        const calldata = CallData.compile({
            order_id : orderId
        }, { parseResponse: false});

        const packedOrder = await contract.call('get_order_with_id', calldata);
        const order = unpackOrderWithID(packedOrder[2]);
        return {...order, side: new BigNumber(packedOrder[1]).toString(), asset: packedOrder[0].activeVariant()};
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getMarketLastOrders = async(address, asset, side, network) => {
    try {
        const provider = getProvider(network);
        const contract = new Contract(OrderbookABI, address, provider);

        const calldata = CallData.compile({
            asset: asset,
            side : side
        }, { parseResponse : false});

        // const response = await contract.get_orders(calldata);
        const response = await contract.call('get_orders', calldata, )
        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getUserData = async (user, market, orderbook, network) => {
    try {
        const provider = getProvider(network);
        const multicallAddress = getMulticallAddress(network);
        const contract = new Contract(MulticallABI, multicallAddress, provider);

        const calldata = CallData.compile({
            user, market, orderbook
        });

        const response = await contract.call('aggregateUserData', calldata);
        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getMarketData = async ( market, orderbook, network) => {
    try {
        const provider = getProvider(network);
        const multicallAddress = getMulticallAddress(network);
        const contract = new Contract(MulticallABI, multicallAddress, provider);

        const calldata = CallData.compile({
            market: market,
            orderbook: orderbook, // TODO orderbookta deployla
        });

        const response = await contract.call('aggregateMarketData', calldata);
        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const getMarketsDataWithOrderbooks = async (orderbooks, network) => {
    try {
        const provider = getProvider(network);
        const multicallAddress = getMulticallAddress(network);
        const statisticsAddress = getStatisticsAddress(network);
        const contract = new Contract(MulticallABI, multicallAddress, provider);

        const calldata = CallData.compile({
            statistics: statisticsAddress,
            orderbooks : orderbooks
        });

        const response = await contract.call('aggregateMultipleMarketsData', calldata);

        return response;
    } catch (ex) {
        console.error(ex);
        return;
    }
}

export const cancelOrder = async (writer, network, orderbook, side, asset, orderid) => {
    const calls = 
        [
            {
                contractAddress: orderbook,
                entrypoint: side === "buy" ? "cancel_buy_order" : "cancel_sell_order",
                calldata: CallData.compile({
                    asset: asset,
                    order_id: orderid
                })
            }
          ]

    const { transaction_hash } = await writer({ calls: calls });
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const claimDistribution = async(writer, network, shareId) => {
    const distributor = getDistributorAddress(network);
    const usdc = getUsdcAddress(network);

    const { transaction_hash } = await writer ({ calls: 
        {
            contractAddress: distributor,
            entrypoint: "claim",
            calldata: CallData.compile({
                token: usdc,
                share_id: shareId
            })
        }});
        
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const mintExpectiumShare = async (writer, network, account, stateId, amount, price) => {
    const shares = getSharesAddress(network);
    const ether = getEthereumAddress(network);

    const call = {
        contractAddress: shares,
        entrypoint: 'mint',
        calldata: CallData.compile({
            state_id: stateId
        })
    };

    const totalAmount = new BigNumber(amount).multipliedBy(new BigNumber(price)).toString();

    const approve = {
        contractAddress: ether,
        entrypoint: 'approve',
        calldata: CallData.compile({
            spender: account,
            amount: totalAmount
        })
    };

    const calls = [approve, ...Array(amount).fill(call)]

    const { transaction_hash } = await writer({calls});
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const mergeShares = async (writer, network, market, amount) => {
    const { contractAddress } = market;


    const { transaction_hash } = await writer({ calls:     
            {
                contractAddress: contractAddress,
                entrypoint: "merge_shares",
                calldata: CallData.compile({
                    invest: cairo.uint256(amount)
                })
            } 
        });
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const mintShares = async (writer, network, market, invest) => {
    const usdcAddress = getUsdcAddress(network);
    const { contractAddress } = market;

    const calls = [
        {
            contractAddress: usdcAddress,
            entrypoint: "approve",
            calldata: CallData.compile({
                spender: contractAddress,
                amount: cairo.uint256(invest)
            }),
        },
        {
            contractAddress: contractAddress,
            entrypoint: "mint_shares",
            calldata: CallData.compile({
                invest: cairo.uint256(invest)
            })
        }
    ];

    const { transaction_hash } = await writer({ calls: calls });
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const convertShares = async (writer, network, market, asset, amount) => {
    const { contractAddress } = market;

    const calls = 
        [
            {
                contractAddress: contractAddress,
                entrypoint: "convert_shares",
                calldata: CallData.compile({
                    asset: asset,
                    amount: cairo.uint256(amount),
                })
            }
          ];

    const { transaction_hash } = await writer({ calls: calls });
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const approveAndInsertBuyOrder = async (writer, network, market, asset, quoteAmount, limitPrice) => {
    const usdcAddress = getUsdcAddress(network);
    const { orderbookAddress } = market;

    const calls = [
        {
            contractAddress: usdcAddress,
            entrypoint: "approve",
            calldata: CallData.compile({
                spender: orderbookAddress,
                amount: cairo.uint256(quoteAmount)
            })
        },
        {
            contractAddress: orderbookAddress,
            entrypoint: 'insert_buy_order',
            calldata: CallData.compile({
                asset: asset,
                quote_amount: cairo.uint256(quoteAmount),
                price: limitPrice
            })
        }
    ]
    const { transaction_hash } = await writer({ calls: calls });
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}

export const approveAndInsertSellOrder = async (writer, network, market, asset, amount, limitPrice) => {
    const { orderbookAddress, contractAddress } = market;

    const calls =
        [
            {
                contractAddress: contractAddress,
                entrypoint: "approve",
                calldata: CallData.compile({
                    spender: orderbookAddress
                })
            },
            {
                contractAddress: orderbookAddress,
                entrypoint: 'insert_sell_order',
                calldata: CallData.compile({
                    asset: asset,
                    amount: cairo.uint256(amount),
                    price: limitPrice
                })
            }
        ]

    const { transaction_hash } = await writer({ calls: calls });
    const provider = getProvider(network)
    return provider.waitForTransaction(transaction_hash)
}