function getPrices(_happens, _not) {
    const _h = Number(_happens)
    const _n = Number(_not)
    
    if(_h == 0 || _n == 0) return { happens: 0, not: 0}
    return {
        happens : (_n / (_h + _n)) * 100,
        not : (_h / (_h + _n)) * 100
    }
}

function formatNumber(_num, _decimals) {
    if(_num && _decimals) {
        return _num / (10**_decimals);
    } else {
        return 0
    }

}

export { getPrices, formatNumber };