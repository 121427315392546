const markets = [
    {
        id: 0,
        contractAddress: '0xdd9a47e72737498dd4394560fd356d3ac8eda54576a8d69599e3e9a4d2bc81',
        orderbookAddress: '0x029d80c96df8d26439655fe83e383beb11c805571d625bf6e3d4da095a9d2165',
        title: 'Will STRK airdrop before March 1?',
        description: `This market will resolve to "Happens" if Starknet launches a token and announces they have performed an airdrop by March 1, 2024, 11:59 PM ET. Otherwise, this market will resolve to "Not".

        For the purposes of this market "locked" tokens or non-swappable tokens will not suffice to resolve this market to "Happens".
        
        The primary resolution source for this market is on-chain information and official information from the Starknet team, however a consensus of credible reporting will also be used.`,
        category: 'Starknet',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Crypto', 'Starknet', 'Airdrops'],
        network: 'mainnet',
        ticker: '$EXPTEST',
        image: "https://starkware.co/wp-content/uploads/2023/02/SN-Symbol-Gradient.png",
        resolveDate : '',
        resolver: "0x00dE8687F1892DD9156a31F8816e39054e5aAA62DDBB3e59C00ee3E9eCD2d6Fe",
        version: 'orderbook',
        homebar: {
            title: 'Airdrops',
            desc: 'Will STRK airdrop before March 1?',
            img: "https://starkware.co/wp-content/uploads/2023/02/SN-Symbol-Gradient.png"
        },
        listAtHome: true,
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 1,
        contractAddress: '0x01190bb3ea26d57b509478b66da3ed7e808ef20d56c59a0cf43999494aad3c6d',
        orderbookAddress: '0x035d8457e6a15a83ce2deacc8f762f21c0bb918eadf7f9b3cf9002da8d1b550a',
        title: 'Will Bitcoin price hit 55000$ before March 1?',
        description: `This market will immediately resolve to “Happens” if any Coinbase 1 minute candle for BTC-USD between 26 Dec '23 8:00 and 1 March '24 23:59 in the ET timezone has a final “High” price of 55,000.00 or higher. Otherwise, this market will resolve to "Not".

        The resolution source for this market is https://www.coinbase.com/, specifically the BTC-USD "High" prices currently available at https://www.coinbase.com/advanced-trade/BTC-USD with “1m” and “Candles” selected on the top bar.
        
        To see the "High" prices, mouse over particular candles and look at the value "H" at the top of the chart.
        
        Please note that this market is about the price according to Coinbase BTC-USD, not according to other sources or spot markets.`,
        category: 'Crypto',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Crypto'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: true,
        homebar: {
            title: 'Price Prediction',
            desc: 'Will Bitcoin price hit 55000$ before March 1?',
            img: "https://cryptologos.cc/logos/bitcoin-btc-logo.png"
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 2,
        contractAddress: '0x056601c557a6d73731f156887b47d6de6fa7e502618ed4d3be3ae0fce41cc300',
        orderbookAddress: '0x06fd6c820763337437a83728d8460c8c6697f9cfc9b11a9b321ed2378a4b6921',
        title: 'Will Fibrous token airdrop before April 1?',
        description: `This market will resolve to "Happens" if Fibrous Finance launches a token and announces they have performed an airdrop by April 1, 2024, 11:59 PM ET. Otherwise, this market will resolve to "Not".

        For the purposes of this market "locked" tokens or non-swappable tokens will not suffice to resolve this market to "Happens".
        
        The primary resolution source for this market is on-chain information and official information from the Fibrous team, however a consensus of credible reporting will also be used.`,
        category: 'Crypto',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Crypto', 'Starknet', 'Airdrops'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://pbs.twimg.com/profile_images/1557424017707073544/u0sk634R_400x400.jpg",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: true,
        homebar: {
            title: 'Airdrops',
            desc: 'Will Fibrous token airdrop before April 1?',
            img: "https://pbs.twimg.com/profile_images/1557424017707073544/u0sk634R_400x400.jpg"
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 3,
        contractAddress: '0x0247bdc9250188f881579814d864e0785bcb770339bb497832e637634b501067',
        orderbookAddress: '0x07e00e077a4b38c9805400b641d574eaa2f67dfe47201d1308aabe170d9bed47',
        title: 'Will 1 USD hit 35 TRY before March 1?',
        description: `This market will resolve yes if 1 USD equal or higher than 35 Turkish Liras in Forex. Otherwise results as 'Not' at March 1.`,
        category: 'Fiat',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Fiat'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://cdn-icons-png.flaticon.com/512/3342/3342780.png",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: false,
        homebar: {
            title: 'Forex',
            desc: 'Will 1 USD hit 35 TRY before March 1?',
            img: "https://cdn-icons-png.flaticon.com/512/3342/3342780.png"
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 4,
        contractAddress: '0xad88aa7be2ff3481e1540cc428c517e24936a2ba1488ca9238a2cd6f999730',
        orderbookAddress: '0x038dca23b88483ec2cf2a8d0135bd13293cc9bf91c4ef31645f0dd3374f95869',
        title: 'Bitcoin ETF approved by Jan 15?',
        description: `This market will resolve to "Yes" if any spot Bitcoin ETF receives approval from the SEC by January 15, 2024, 11:59:59 PM ET. Otherwise, this market will resolve to "No".

        The primary resolution source for this market will be information from the SEC, however a consensus of credible reporting may also be used.`,
        category: 'Finance',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Crypto', 'Finance'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://seeklogo.com/images/S/securities-and-exchange-commission-sec-logo-23088CC87C-seeklogo.com.png",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: true,
        homebar: {
            title: 'Finance',
            desc: 'Bitcoin ETF approved by Jan 15?',
            img: "https://seeklogo.com/images/S/securities-and-exchange-commission-sec-logo-23088CC87C-seeklogo.com.png"
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 5,
        contractAddress: '0x06aa0befb2131a09e7f3b54cb12e4e718eec9bfb90b1a9b328e6256bfbc9c640',
        orderbookAddress: '0x0258ab3885747607be71fcfb57076f2cdebee7c3cd3e37a03229616a964105f6',
        title: 'Trump and Biden both win nomination?',
        description: `This market will resolve to "Yes" if Joseph Biden wins the Democratic nomination for president of the United States and Donald Trump wins the Republican nomination for president of the United States in the 2024 US presidential election. Otherwise, this market will resolve to "No".

        For this market to resolve to "Yes", both named candidates must win the described nominations. If one wins and the other does not, it will not be sufficient to resolve this market to "Yes".
        
        The primary resolution source for this market will be information from the Democratic and Republican national conventions, however a consensus of credible reporting will also be used.
        
        Any replacement of the nominee before election day will not change the resolution of the market.`,
        category: 'Elections',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Elections', 'Politics'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://media.vanityfair.com/photos/5f2b1191ddd2ccafea1cd5ab/master/w_2560%2Cc_limit/joe-biden-trump-debate-lede.jpg",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: false,
        homebar: {
            title: 'Elections',
            desc: 'Trump and Biden both win nomination?',
            img: "https://media.vanityfair.com/photos/5f2b1191ddd2ccafea1cd5ab/master/w_2560%2Cc_limit/joe-biden-trump-debate-lede.jpg"
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 6,
        contractAddress: '0x07c05faf6215a03fb575773f5541c29b0833442b18c2f273f05296926cb83337',
        orderbookAddress: '0x1fbfe6a3c1e26788a00b15ecdb0ff48739d0278e879b771b1de89114436d87',
        title: 'Will ETH hit $2,500 by Jan 31?',
        description: `This market will immediately resolve to “Happens” if any Coinbase 1 minute candle for ETH-USD between 26 Dec '23 8:00 and 31 Jan '24 23:59 in the ET timezone has a final “High” price of 2,500.00 or higher. Otherwise, this market will resolve to "Not".

        The resolution source for this market is https://www.coinbase.com/, specifically the ETH-USD "High" prices currently available at https://www.coinbase.com/advanced-trade/ETH-USD with “1m” and “Candles” selected on the top bar.
        
        To see the "High" prices, mouse over particular candles and look at the value "H" at the top of the chart.
        
        Please note that this market is about the price according to Coinbase ETH-USD, not according to other sources or spot markets.`,
        category: 'Crypto',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Crypto'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://seeklogo.com/images/E/ethereum-blue-logo-8BC914153E-seeklogo.com.png",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: false,
        homebar: {
            title: '',
            desc: '',
            img: ""
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
    {
        id: 7,
        contractAddress: '0x05e76eb8c77fa8812a4a0d5941bf5aa698bc1fc80ac9336d1852f3627a743aa7',
        orderbookAddress: '0x025c400788a47268dd27450f5fd37bbbcf3c65937492ce8f3676c3d75e56ef16',
        title: 'CZ jail before March?',
        description: `This market will resolve to "Yes" if Changpeng Zhao (CZ) spends any time in custody in a jail or prison between December 25 and February 29, 2024, 11:59 PM ET. Otherwise, this market will resolve to "No".

        The primary resolution source for this market will be official information from involved government(s), however a consensus of credible reporting may also be used.`,
        category: 'Celebrities',
        categoryFilters: ['All', 'Top', 'New', 'Popular', 'Crypto', 'Celebrities'],
        network: 'mainnet',
        ticker: '$EXPTEST2',
        image: "https://i.seadn.io/gae/0c_Yd0UdDIROqrOMGwvKgNzNWvyu4fkDmKjn2R8fMXHIenMb9t-zOfaB2Uim3WU8IioK3Lg21LPdJsrHFpYSPiKzaihuo6zgTZ2rgNE?auto=format&dpr=1&w=1000",
        resolveDate : '',
        resolver: "0x00df91b13d50e3935d404ed0497aa2ca345044544623075ae7ce56c432102b3a",
        version: 'orderbook',
        listAtHome: false,
        homebar: {
            title: '',
            desc: '',
            img: ""
        },
        fees: {
            maker : '0.5', // %
            taker : '1'
        }
    },
]

export default markets;