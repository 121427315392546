import { createSlice } from "@reduxjs/toolkit";
import fetchBalances from "./fetchBalances";

const initialState = { usdc : "0" }

export const userSlice = createSlice({
    name: 'UserInfo',
    initialState,
    reducers: {
        setUserBalances: (state, action) => {
            const liveBalanceData = action.payload;
            state.data = liveBalanceData 
        }
    }
})

export const { setUserBalances } = userSlice.actions;

export const fetchUserBalancesAsync = (account, network) => async (dispatch) => {
    const balance = await fetchBalances(account, network);
    dispatch(setUserBalances(balance));
}

export default userSlice.reducer;