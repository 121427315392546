import { useState, useEffect } from "react";
import { Container, Stack, Stat, StatLabel, StatNumber, StatHelpText, Box, Flex, Heading, Text, Card, CardHeader, CardBody, StackDivider, Skeleton, Button, Grid, GridItem, useToast, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from "@chakra-ui/react"
import { CheckCircleIcon, CloseIcon, SpinnerIcon, CheckIcon } from '@chakra-ui/icons';
import { getUserShares, getSharesPublicInfo, claimDistribution, mintExpectiumShare } from '../../utils/callHelpers.js';
import { useConnect, useDisconnect, useAccount, useContractWrite } from "@starknet-react/core";
import useRefresh from "../../hooks/useRefresh.js";
import { formatAddress } from "../../utils/formatAddress.js";
import { getBalanceNumber, getFullDisplayBalance } from "../../utils/formatNumber.js";
import BigNumber from "bignumber.js";
import { SHARES_LATEST_STATE } from "../../config/contants.js";

export default function Shares() {
    const [supply, setSupply] = useState(0);
    const [available, setAvailable] = useState(0);
    const [distributed, setDistributed] = useState(0);
    const [shares, setShares] = useState(-1);
    const { slowRefresh } = useRefresh();

    const { address } = useAccount();

    useEffect(() => {
        async function updateState() {
          try {
            const response = await getSharesPublicInfo('mainnet');
            console.log(response)
            if(response.hasOwnProperty('total_supply')) {
              setSupply(response.total_supply.toString())
              setDistributed(getBalanceNumber(response.total_distribution, 6).toFixed(2))
              setAvailable(response?.state?.amount.toString());
            }

            if(address) {
              const usershares = await getUserShares('mainnet', address)
              setShares(usershares.user_share_ids)
            }
          } catch (ex) {
            console.error(ex)
          }
        }
        updateState()
    }, [address, slowRefresh]);

    return (
        <Container maxW={'6xl'}>
            <Stack mt={5} direction={'row'}>
                <DataViewer title="Current Supply" value={supply} helper="Total minted amount"/>
                <DataViewer title="Available" value={available} helper="For minting"/>
                <DataViewer title="Distributed USDC" value={`$${getFullDisplayBalance(distributed, 6, 2)}`} helper="Total distribution"/>
            </Stack>
            {address ? <Connected shares={shares} available={available} /> : <NotConnected />}
            
        </Container>
    )
}

const DataViewer = ({title, value, helper}) => {
    return (
        
            <Stat>
                <StatLabel>{title}</StatLabel>
                <StatNumber>{value}</StatNumber>
                <StatHelpText>{helper}</StatHelpText>
            </Stat>
    )
}

const ShareClaims = ({shares}) => {
  const [ontxn, setOnTxn] = useState(false);
  const { writeAsync } = useContractWrite({})
  const toast = useToast();

  async function claim(shareId) {
    try {
      setOnTxn(true);

      await claimDistribution(writeAsync, 'mainnet', shareId)
      toast({
          title: 'Transaction Broadcasted',
          description: `Transaction successfully broadcasted.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
      })
    } catch (ex) {
      toast({
          title: 'Error during sending transaction',
          description: ex.message? ex.message : 'Unknown error',
          status: 'error',
          duration: 5000,
          isClosable: true,
      })
    } finally {
      setOnTxn(false)
    }
    
  }

    return (
      <Grid templateColumns={'repeat(6, 1fr)'} gap={2} width={'100%'} maxHeight={'225px'} height={'225px'} overflowY={'auto'} padding={2}>
        {shares.map((share) => (
          <GridItem w={'100%'}>
            <Button width={'100%'} height={'60px'} fontSize={'md'} colorScheme={getBalanceNumber(share[1].toString(), 6) > 0 ? 'green' : 'gray'} 
                      fontWeight={600} 
                      onClick={getBalanceNumber(share[1].toString(), 6) > 0 ? () => claim(share[0].toString()) : () => {}}>
                      <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-around'}>
                        <Text>
                        {share[0].toString()}
                        </Text>
                        <Text>
                        ${getBalanceNumber(share[1].toString(), 6).toString()}
                        </Text>
                      </Stack>

            </Button>
          </GridItem>
        ))}
      </Grid>
    )
}

const Connected = ({shares, available}) => {
    const { address } = useAccount();

    const isLoaded = Array.isArray(shares) && shares.length > 0;
    return (
        <Card marginTop={'20px'} marginBottom={'20px'}>
        <CardHeader>
          <Heading size='md'>Your Shares {`(${formatAddress(address)})`}</Heading>
        </CardHeader>
      
        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                You own
              </Heading>
              <Skeleton isLoaded={Array.isArray(shares)}>
                <Text pt='2' fontSize='sm'>
                  <b>{shares.length}</b> shares.
                  You can check your Share Ids from section at bottom.
                </Text>
              </Skeleton>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase' paddingBottom={'5px'}>
                Your Shares claimable amounts (USDC)
              </Heading>
              <Text fontSize='sm' paddingBottom={'5px'}>You can click your shares to claim distributed USDC. Available balances turns green color.</Text>
              {isLoaded ? <ShareClaims shares={shares}/> : <></>}
              
            </Box>
            <Box width={'100%'}>
              <Heading size='xs' textTransform='uppercase'>
                Mint Shares
              </Heading>
                {available > 0 ? <MintingAvailable available={available} /> : <Text pt={2} size={'sm'}>No mintings available at the moment.</Text>}
            </Box>
          </Stack>
        </CardBody>
      </Card>  
    )
}

const MintingAvailable = ({available}) => {
  const [price, setPrice] = useState('0');
  const [loaded, setLoaded] = useState(false);
  const [amount, setAmount] = useState(1);
  const [onTxn, setOnTxn] = useState(false);
  const toast = useToast();
  const { writeAsync } = useContractWrite({})
  const {account} = useAccount();

  const stateId = SHARES_LATEST_STATE;

  useEffect(() => {
    async function updateInfo() {
      try {
        const response = await getSharesPublicInfo('mainnet');
        if(response.hasOwnProperty('state')) {
          setPrice(new BigNumber(response.state?.price).toString());
          setLoaded(true);
        }
      } catch (ex) {
        console.error(ex)
        return;
      }
    }
    updateInfo();
  }, [available])

  async function mint() {
    try {
      setOnTxn(true);

      // await claimDistribution(writeAsync, 'mainnet', )
      await mintExpectiumShare(writeAsync, 'mainnet', account, stateId, amount, price) // multiple yap
      toast({
          title: 'Transaction Broadcasted',
          description: `Transaction successfully broadcasted.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
      })
    } catch (ex) {
      toast({
          title: 'Error during sending transaction',
          description: ex.message? ex.message : 'Unknown error',
          status: 'error',
          duration: 5000,
          isClosable: true,
      })
    } finally {
      setOnTxn(false)
    }
    
  }

  return  (
    <Stack display={'flex'} flexDirection={'column'} width={'100%'} alignItems={'flex-start'} pt={2}>
      <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Text>
            Minting price:
          </Text>
        <Skeleton isLoaded={loaded}>
          <Text fontWeight={600}>
              {getBalanceNumber(price, 18)} Ether
          </Text>
        </Skeleton>
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Text>
            Amount:
          </Text>
          <Text fontWeight={600}>
              {amount}
          </Text>
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Text>
            Total Cost:
          </Text>
          <Text fontWeight={600}>
              {getBalanceNumber(new BigNumber(price).multipliedBy(new BigNumber(amount)).toString(), 18)} Ether
          </Text>
      </Stack>
      <Skeleton isLoaded={loaded} width={'35%'}>
      <Stack display={'flex'} flexDirection={'column'} width={'100%'}>
        <Slider defaultValue={1} min={1} max={10} step={1} onChange={(x) => setAmount(x)}>
          <SliderTrack>
            <SliderFilledTrack bg="green" />
          </SliderTrack>
          <SliderThumb boxSize={6} />
        </Slider>
        <Button colorScheme="green" onClick={() => mint()}>Mint</Button>
      </Stack>
      </Skeleton>
    </Stack>

  )
}

const NotConnected = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
                <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={'red.500'}
                rounded={'50px'}
                w={'55px'}
                h={'55px'}
                textAlign="center">
                <CloseIcon boxSize={'20px'} color={'white'} />
                </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
                You are not connected
            </Heading>
            <Text color={'gray.500'}>
                Connect your wallet to see your Expectium Shares revenue.
            </Text>
        </Box>
    )
}