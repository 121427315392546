import { Box, Stack, Image, Text, IconButton , useColorModeValue, Skeleton } from "@chakra-ui/react"
import { HiOutlineLightBulb } from "react-icons/hi";
import { getBalanceNumber } from "../../../utils/formatNumber";
import { useNavigate } from "react-router-dom"
import { DOCUMENTATION_BULB } from "../../../config/contants";

const MarketBox = ({market}) => {
    const navigate = useNavigate();
    const categoryColor = useColorModeValue('gray.600', 'gray.300')
    const priceBarGreenBgColor = useColorModeValue('green.100', 'green.300');
    const priceBarRedBgColor = useColorModeValue('red.100', 'red.300');
    const priceBarGreenTextColor = useColorModeValue('green.400', 'green.800');
    const priceBarRedTextColor = useColorModeValue('red.400', 'red.800');


    const isLoaded = market.hasOwnProperty('liveMarketData');

    const prices = isLoaded ? getPrices(market.liveMarketData?.orders?.happens?.best_sell, market.liveMarketData?.orders?.not?.best_sell) : {yes : 50, no: 50}
    const liquidity = isLoaded ? getBalanceNumber(market.liveMarketData?.liquidity,6).toFixed(2) : '0'
    const isResolved = isLoaded ? market.liveMarketData?.isResolved : false;
    const resolveRate = isResolved ? market.liveMarketData?.resolve_rate : { happens: "0", not: "0"};

    function getPrices(happens, not) {
        if(happens === '0' || not === '0') {
            return {
                yes: 50,
                no: 50
            }
        }

        const happensFl = parseInt(happens) / 100;
        const notFl = parseInt(not) / 100;

        if(happensFl + notFl < 100) {
            if(happensFl > notFl) {
                return {
                    yes: happensFl,
                    no: 100 - happensFl
                }
            } else {
                return {
                    yes: 100 - notFl,
                    no: notFl 
                }
            }

        } else {
            return {
                yes: happensFl,
                no: notFl
            }
        }
    }
    console.log(market)
    return (
        <Box minW={'330px'} width={['100%','23%']} height={'200px'} borderRadius={'10px'} 
            padding={4} shadow={'md'} _hover={{shadow: 'xl'}} transition={'0.3s'}>
                <Stack width={'100%'} display={'flex'} flexDirection={'column'} height={'100%'}>
                    <Stack width={'100%'} height={'60%'} display={'flex'} flexDirection={'row'}>
                        <Image boxSize={'85px'} src={market.image} borderRadius={'4px'} onClick={() => navigate(`/market/${market.contractAddress}`)} cursor={'pointer'}/>
                        <Stack display={'flex'} flexDirection={'column'} width={'100%'} padding={2}>
                            <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Text fontSize={'xs'} color={categoryColor}>{market.category}</Text>
                                <IconButton size={'xs'} fontSize={'13px'} icon={<HiOutlineLightBulb />} 
                                        onClick={() => window.open(DOCUMENTATION_BULB)}
                                /> 
                            </Stack>
                            <Text fontSize={'14px'} fontWeight={600} 
                                    onClick={() => navigate(`/market/${market.contractAddress}`)}
                                    cursor={'pointer'}>{market.title}</Text>
                        </Stack>
                    </Stack>
                        <Stack display={'flex'} flexDirection={'column'} height={'35%'} width={'100%'} justifyContent={'space-between'} 
                                onClick={() => navigate(`/market/${market.contractAddress}`)} cursor={'pointer'}>
                                {isResolved ? (
                                    <Skeleton isLoaded={isLoaded}>
                                        <Stack display={'flex'} flexDirection={'row'} width={'100%'} height={'70%'}>
                                            <Box width={'100%'} height={'30px'} bgColor={Number(resolveRate.happens) > 0 ? priceBarGreenBgColor : priceBarRedBgColor}
                                                display={'flex'} alignItems={'center'} justifyContent={'flex-start'} padding={1}>
                                                <Text minWidth={'65px'} color={Number(resolveRate.happens) > 0 ? priceBarGreenTextColor : priceBarRedBgColor} fontWeight={500} fontSize={'xs'} textAlign={'left'}>
                                                    Market resolved as {Number(resolveRate.happens) > 0 ? 'Happens' : 'Not'}
                                                </Text>
                                            </Box>
                                        </Stack>
                                    </Skeleton>
                                ) : (
                                    <Skeleton isLoaded={isLoaded}>
                                        <Stack display={'flex'} flexDirection={'row'} width={'100%'} height={'70%'}>
                                            <Box width={`${prices.yes}%`} height={'30px'} bgColor={priceBarGreenBgColor} borderRadius={'4px'}
                                                display={'flex'} alignItems={'center'} justifyContent={'flex-start'} padding={1}>
                                                <Text minWidth={'65px'} color={priceBarGreenTextColor} fontWeight={500} fontSize={'xs'} textAlign={'left'}>Yes {prices.yes}¢</Text>
                                            </Box>
                                            <Box width={`${prices.no}%`} height={'30px'} bgColor={priceBarRedBgColor} borderRadius={'4px'} 
                                                display={'flex'} alignItems={'center'} justifyContent={'flex-end'} padding={1}>
                                                <Text minWidth={'65px'} fontWeight={500} fontSize={'xs'} textAlign={'right'} color={priceBarRedTextColor}>No {prices.no}¢</Text>
                                            </Box>
                                        </Stack>
                                    </Skeleton>
                                )}

                            <Box height={'20%'}>
                                <Skeleton isLoaded={isLoaded}>
                                    <Text fontSize={'xs'} fontWeight={500} color={categoryColor}>${liquidity} Invested</Text>
                                </Skeleton>
                            </Box>
                        </Stack>
                </Stack>
        </Box>
    )
}

export default MarketBox;