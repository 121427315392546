import { Stack, Heading, Skeleton, Image, Text, Grid, GridItem } from "@chakra-ui/react";
import { getImageFromOrderbook, getMarketFromOrderbook, getRandomImageFromAddress, getMarketFromMarket } from "../../../utils/imageUtils";
import { formatAddress } from "../../../utils/formatAddress";
import { getBalanceNumber } from "../../../utils/formatNumber";

const TopVolumersx = ({isLoaded, volumers}) => {
    return (
        <Stack width={'100%'}>
            <Heading size={'md'}>Top Volumers</Heading>
            <Skeleton isLoaded={isLoaded}>
                <Grid templateColumns={'repeat(2, 1fr)'} gap={6} templateRows={'repeat(2, 1fr)'} pt={2} pb={2}>
                {volumers.map((volumer, index) => (
                    index < 12 ? <GridItem><VolumeBar volumer={volumer} /></GridItem> : (<></>)
                ))}
                </Grid>
            </Skeleton>
        </Stack>
    )
}
const TopVolumers = ({isLoaded, volumers}) => {
    return (
        <Stack width={'100%'}>
            <Heading size={'md'}>Top Volumers</Heading>
            <Text fontSize={'sm'} color={'gray.500'} fontWeight={400}>Top Volumers data will be activated.</Text>
        </Stack>
    )
}

const VolumeBar = ({volumer}) => {
    const randomAvatar = getRandomImageFromAddress(volumer.address)
    return (
        <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Image borderRadius={4} src={randomAvatar} height={50} />
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
            <Text fontWeight={600}>{formatAddress(volumer.address)}</Text>
            <Text fontSize={'xs'} fontWeight={600} color={'gray.500'}>${getBalanceNumber(volumer.volume / 100, 6).toFixed(2)}</Text>
        </Stack>

    </Stack>
    )
}

export default TopVolumers;