import React, { useState } from "react";

const NetworkContext = React.createContext({ network : 'mainnet', changeNetwork: (network) => null});

const NetworkContextProvider = ({ children }) => {
    const [network, setNetwork] = useState('mainnet');
    const changeNetwork = (_network) => {
        if(_network === 'mainnet') {
            setNetwork(_network)
        } else {
            alert('network not supported')
        }

    }
    return (
        <NetworkContext.Provider value={{ network, changeNetwork }}>
            {children}
        </NetworkContext.Provider>
    )
}

export { NetworkContext, NetworkContextProvider }