import { createIcon } from "@chakra-ui/react";

export const FinishIcon = createIcon({
    displayName: 'FinishIcon',
    viewBox : '0 0 24 24',
    path : (
        <path fill='currentColor' id="Vector" d="M4 21V15.6871M4 15.6871C9.81818 11.1377 14.1818 20.2363 20 15.6869V4.31347C14.1818 8.86284 9.81818 -0.236103 4 4.31327V15.6871Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    )
});

export const VolumeIcon = createIcon({
    displayName : 'VolumeIcon',
    viewBox : '0 0 24 24',
    path : (
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 3.93a.75.75 0 0 1 1.177-.617l4.432 3.069a.75.75 0 0 1 0 1.233l-4.432 3.069A.75.75 0 0 1 16 10.067V8H4a1 1 0 0 1 0-2h12V3.93zm-9.177 9.383A.75.75 0 0 1 8 13.93V16h12a1 1 0 1 1 0 2H8v2.067a.75.75 0 0 1-1.177.617l-4.432-3.069a.75.75 0 0 1 0-1.233l4.432-3.069z" fill="currentColor"/>
    )
})

export const LiquidityIcon = createIcon({
    displayName : 'LiquidityIcon',
    viewBox : '0 0 24 24',
    path : (
        <>
              <path d="M12,20a6,6,0,0,1-6-6c0-4,6-10.8,6-10.8S18,10,18,14A6,6,0,0,1,12,20Z" fill="currentColor"/>
                <rect width="24" height="24" fill="none"/>
        </>
    )
})