import { Modal, ModalOverlay, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalFooter, Button, Text, Stack } from '@chakra-ui/react';

export default function OrderCancelConfirmationModal({isOpen, onClose}) {
    return (
            <Modal size={'xl'} isCentered isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay
                    bg='blackAlpha.500'
                    backdropFilter='blur(5px) hue-rotate(45deg)'
                    />
                    <ModalContent>
                    <ModalHeader>Are you sure to cancel order?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Text>
                        Your order with id ### will be cancelled after your confirmation.
                    </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Stack justifyContent={'space-evenly'} direction={'row'}>
                            <Button colorScheme={'green'} onClick={onClose}>Cancel Order</Button>
                            <Button onClick={onClose}>Return</Button>
                        </Stack>
                    </ModalFooter>
                    </ModalContent>
            </Modal>
    )
}