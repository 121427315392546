import { Stack, Divider, Text, Heading, TableContainer, Table, Skeleton, 
        Thead, Tr, Tbody, Td, Th, Button, useDisclosure, useToast, Box } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons'
import OrderCancelConfirmationModal from './OrderCancelConfirmationModal';
import { useMarketFromContract } from "../../../state/hooks";
import { unpackOrder, unpackOrderWithID } from "../../../utils/orderHelpers";
import { getOrderFromID, cancelOrder } from "../../../utils/callHelpers";
import { useContext, useEffect } from "react";
import { NetworkContext } from "../../../contexts/NetworkContext";
import { getBalanceNumber } from "../../../utils/formatNumber";
import BigNumber from "bignumber.js";
import { useAccount, useContractWrite } from "@starknet-react/core";

export default function UserOrders({ contractAddress }) {
    const confirmationModal = useDisclosure();
    const market = useMarketFromContract(contractAddress);
    const { network } = useContext(NetworkContext);
    const toast = useToast();
    
    const { writeAsync } = useContractWrite({})

    const isLoaded = market.hasOwnProperty('userData');
    console.log( market.userData)
    async function submitCancelTransaction(side, asset, orderid) {
        const assetString = asset === 'happens' ? "0" : "1"
        try {
            await cancelOrder(writeAsync, network, market.orderbookAddress, side, assetString, orderid)
            toast({
                title: 'Transaction Broadcasted',
                description: `Transaction successfully broadcasted.`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        } catch (ex) {
            toast({
                title: 'Error during sending transaction',
                description: ex.message? ex.message : 'Unknown error',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }

    }
    return (
        
            <Stack pt={5} >
            <OrderCancelConfirmationModal isOpen={confirmationModal.isOpen} onClose ={confirmationModal.onClose}/>
                
                <TableContainer pb={5}>
                <Heading size={'md'} mb={5}>Your Orders</Heading>
                <Skeleton isLoaded={isLoaded}>
                <Divider />
                <Box overflowY={'auto'} maxH={'200'}>
                <Table variant='simple' size="sm">
                        <Thead>
                        <Tr>
                            <Th>#ID</Th>
                            <Th>Side</Th>
                            <Th>Commodity</Th>
                            <Th isNumeric>Amount</Th>
                            <Th isNumeric>Price</Th>
                            <Th isNumeric>Cancel</Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                        {isLoaded && market.userData.balances.userOrders.length > 0 ? (market.userData?.balances.userOrders.map((order) => {
                            
                            return (
                                <Tr>
                                <Td>#{order?.orderid}</Td>
                                <Td color={order.side === "buy" ? 'green.300' : 'red.300'}>{order.side === "buy" ? 'Buy' : 'Sell'}</Td>
                                <Td>{order.asset.toUpperCase()}</Td>
                                <Td isNumeric>{new BigNumber(getBalanceNumber(order.amount, 6)).toString()}</Td>
                                <Td isNumeric>¢ {new BigNumber(order.price / 100).toString()}</Td>
                                <Td isNumeric>
                                    <Stack alignItems={'flex-end'} justifyContent={'center'}>
                                        <Button size={'xs'} onClick={() => submitCancelTransaction(order.side, order.asset, order.orderid)}>
                                                <CloseIcon />
                                        </Button>         
                                    </Stack>
                                </Td>
                            </Tr>
                            )
                        })) : <></>}
                        </Tbody>
                    </Table>
                </Box>

                    </Skeleton>
                </TableContainer>
            </Stack>
    )
}