import { useDisclosure, Button, useColorModeValue } from '@chakra-ui/react'
import ConnectWalletModal from './ConnectWalletModal'
import { useEffect, useState } from 'react';
import { formatAddress } from '../../utils/formatAddress';
import { useConnect, useDisconnect, useAccount } from "@starknet-react/core";

const ConnectWallet = function() {

  const { disconnect } = useDisconnect();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { address } = useAccount();

  async function disconnectWallet() {
    disconnect();
  }


  return (
      <>
      {address ? (
        <Button size={['sm', 'md']} onClick={disconnectWallet} colorScheme='blue'>
            {formatAddress(address)}
        </Button>
      ) : (
        <Button size={['sm', 'md']} onClick={onOpen} colorScheme='blue'>
            Connect Wallet
        </Button>

      )}
      <ConnectWalletModal title={"Connect Wallet"} isOpen={isOpen} onClose={onClose} />
      </>
  )
}

export default ConnectWallet;