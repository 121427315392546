import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
    Stack,
    Container,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading
  } from '@chakra-ui/react'
import { useMarkets } from '../../state/hooks'
import BigNumber from 'bignumber.js';
import { getBalanceNumber } from '../../utils/formatNumber';
import { Link, Link as RouterLink } from 'react-router-dom';

export default function Statistics() {
    const markets = useMarkets();
    console.log(markets)
    const isLoaded = markets.length > 0 && markets[0].hasOwnProperty('liveMarketData');
    const totalValueLocked = isLoaded ? markets.reduce((acc, cur) => new BigNumber(acc).plus(new BigNumber(cur.liveMarketData?.collateralized)), new BigNumber(0)) : '0';

    const completedEvents = isLoaded ? markets.filter((m) => m.liveMarketData?.isResolved) : [];

    const highestVolumeEvents = isLoaded ? [...markets].sort((x, y) => new BigNumber(y.liveMarketData?.volume).minus(new BigNumber(x.liveMarketData?.volume))).slice(0, 5): [];

    console.log(highestVolumeEvents)
    const lastEvents = [
        {
            ticker : "$EXPFED05",
            explanation : "Fed Interest Rate Decision",
            volume : "###"
        },
        {
            ticker : "$EXPETH05",
            explanation : "Ethereum Price Prediction",
            volume : "###"
        },
        {
            ticker : "$EXPBTC05",
            explanation : "Bitcoin Price Prediction",
            volume : "###"
        }
    ]
    return (
        <Container maxW={'3xl'}>
            <Stack mt={5} direction={'row'}>
                <DataViewer title="Total Value Locked" value={`$ ` + getBalanceNumber(totalValueLocked.toString(), 6).toFixed(2)} helper="Concurrent"/>
                <DataViewer title="Events Created" value={markets.length} helper="Total"/>
                <DataViewer title="Events Completed" value={completedEvents.length} helper="Total"/>
            </Stack>
            <Stack mt={5}>
                <TableViewer title={"Highest Volume Events"} caption={"Statistics may updated late."} 
                    header={[{value : "Ticker"}, {value : "Description"}, {value : "Volume", isNumeric: true}]} data={highestVolumeEvents}
                />
            </Stack>


        </Container>
    )
}

const DataViewer = ({title, value, helper}) => {
    return (
        
            <Stat>
                <StatLabel>{title}</StatLabel>
                <StatNumber>{value}</StatNumber>
                <StatHelpText>{helper}</StatHelpText>
            </Stat>
    )
}

const TableViewer = ({title, caption, data, header}) => {
    return (
        <TableContainer>
            <Heading size={'md'} pb={2}>
                {title}
            </Heading>
            <Table variant='simple' size="sm">
                <TableCaption>{caption}</TableCaption>
                <Thead>
                <Tr>
                {header.map((h) => (
                    <Th isNumeric={h.isNumeric}>{h.value}</Th>
                ))}
                </Tr>
                </Thead>
                <Tbody>
                {data.map((d) => (
                    <Tr cursor={'pointer'} _hover={{color : 'green.400'}} onClick={ () => window.open(`/market/${d.contractAddress}`)}>
                        <Td>{d.ticker}</Td>
                        <Td>{d.title}</Td>
                        <Td>$ {getBalanceNumber(d.liveMarketData?.volume, 6).toFixed(2)}</Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

// TODO son işlemleri ekle